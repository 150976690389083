import Section from "components/layouts/Section";

import s from "./index.module.scss";
import List from "components/common/List";
import Icon from "components/common/Icon";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import RevealTextByLine from "components/common/RevealInView/RevealTextByLine";
import SlideFadeOutWhenInView from "components/views/ViewPortfolioItem/ViewPortfolioNovaGroup/SlideFadeOutWhenInView";
import ScrollDownButton from "components/layouts/ScrollDownButton";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";

const sectionData = {
  title: "Мы работаем",
  subtitle:
    "более 10 лет в строительной и архитектурной тематике и реализовали больше 30 проектов",
  headText: "О поддержке сайтов застройщиков",

  jobTypes: [
    "виды работ",
    "дизайн поддержка",
    "техническая поддержка",
    "доработки",
    "реализация новых функций",
  ],
  jobWith: [
    "работаем с",
    "застройщиками",
    "строительными компаниями",
    "промышленными компаниями",
    "ландшафтными дизайнерами",
    "производителями оборудования",
    "архитектурными фирмами",
    "риэлторскими агентствами",
    "инфраструктурными проектами",
    "производителями стройматериалов",
    "фирмами по ремонту и реставрации",
  ],
  footerTitle: "С нами работают",
  footerItems: ["sibur.svg", "skess.svg", "gasprom-neft.svg", "sk10.svg"],
  anchor: () => (
    <>
      Посмотреть <br /> тексты
    </>
  ),
};

function ViewDeveloperSitesOurExperienceSection() {
  return (
    <DynamicBackgroundTrigger>
      <Section
        className={
          s["view-developer-sites-our-experience-section"] +
          " background--gray view-developer-sites-our-experience-section"
        }
      >
        <div className={" section-container container"}>
          <div className="view-about-services-section__header">
            <p className="view-about-services-section__overline label">
              <RevealWhenInView
                tag="span"
                targetTag="span"
                targetClassName="side-brackets"
                duration={0.6}
              >
                {sectionData.headText}
              </RevealWhenInView>
            </p>
            <h2 className="view-about-services-section__heading heading">
              <span className="view-about-services-section__heading-term">
                <RevealWhenInView tag="span" targetTag="span" duration={0.6}>
                  {sectionData.title}
                  &nbsp;&mdash;&nbsp;
                </RevealWhenInView>
              </span>
              <span className="view-about-services-section__heading-description">
                <RevealTextByLine tag="span">
                  {sectionData.subtitle}
                </RevealTextByLine>
              </span>
            </h2>
          </div>
          <div
            className={
              s["view-developer-sites-our-experience-section__body"] + " row"
            }
          >
            {/* Начало первого листа */}
            <div className="view-services-section__col-with-technologies col-11 col-md-4 col-xl-5">
              <SlideFadeOutWhenInView>
                {({ setTarget, setTriggerEl }) => (
                  <div ref={setTriggerEl} className="row">
                    <div className="view-services-section__col-with-technologies-label col-11">
                      <p ref={setTarget()}>
                        <span className="view-services-section__technologies-label sublabel sublabel--secondary">
                          {sectionData.jobTypes[0]}
                        </span>
                      </p>
                    </div>
                    <List
                      ref={setTarget({ selector: ".list__item" })}
                      items={sectionData.jobTypes.slice(1)}
                      marked
                      className={
                        "view-services-section__technologies-list sublabel sublabel--secondary col-11 " +
                        s[
                          "view-developer-sites-our-experience-section__job-types-list"
                        ]
                      }
                    ></List>
                  </div>
                )}
              </SlideFadeOutWhenInView>
            </div>
            {/* Начало второго листа */}
            <div className="col-11 order-md-3 col-md-7 col-xl-5 col-xl-1 row">
              <SlideFadeOutWhenInView>
                {({ setTriggerEl, setTarget }) => (
                  <div ref={setTriggerEl} className="row col-11 ">
                    <div className=" col-11 col-xl-3">
                      <p
                        ref={setTarget()}
                        className={
                          "label side-brackets " +
                          s[
                            "view-developer-sites-our-experience-section__caption-list-title"
                          ]
                        }
                      >
                        {sectionData.jobWith[0]}
                      </p>
                    </div>
                    <List
                      ref={setTarget({ selector: ".list__item" })}
                      items={sectionData.jobWith.slice(1)}
                      marked
                      gap="lg"
                      className="col-11 col-xl-6 offset-xl-2"
                    ></List>
                  </div>
                )}
              </SlideFadeOutWhenInView>
            </div>
          </div>

          <div
            className={
              "row col-1x1 " +
              s["view-developer-sites-our-experience-section__footer"]
            }
          >
            <div
              className={
                "row col-auto col-md-7 " +
                s[
                  "view-developer-sites-our-experience-section__awards-container"
                ]
              }
            >
              <RevealWhenInView className={s.text}>
                <p className={"side-brackets label "}>
                  {sectionData.footerTitle}
                </p>
              </RevealWhenInView>
              <div
                className={
                  "row " +
                  s["view-developer-sites-our-experience-section__footer-items"]
                }
              >
                {sectionData.footerItems.map((item, index) => {
                  return (
                    <RevealWhenInView key={index}>
                      <img
                        className="awards-img"
                        src={`/images/projects-icon/${item}`}
                        width="70"
                        height="70"
                      />
                    </RevealWhenInView>
                  );
                })}
              </div>
            </div>
            <div className="offset-auto row">
              <RevealWhenInView className="m-top-auto">
                <ScrollDownButton
                  scrollTo=".view-developer-sites-swiper-section"
                  duration={0.5}
                  className={"scroll-down-button-with-text"}
                >
                  <p>
                    Посмотреть <br /> кейсы
                  </p>
                </ScrollDownButton>
              </RevealWhenInView>
            </div>
          </div>
        </div>
      </Section>
    </DynamicBackgroundTrigger>
  );
}

export default ViewDeveloperSitesOurExperienceSection;
