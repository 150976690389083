import classNames from "classnames";
import React from "react";
import { ReactNode } from "react";

interface ListItemProps {
  className?: string;
  children?: ReactNode;
}

function hasTextContent(node: React.ReactNode): boolean {
  if (typeof node === "string") {
    return node.trim() !== "";
  } else if (React.isValidElement(node)) {
    // Если это элемент, рекурсивно проверяем его детей
    return React.Children.toArray(node.props.children || node.props.data).some(hasTextContent);
  }
  return false;
}

const ListItem = ({ className, children }: ListItemProps) => {
  const hasText = hasTextContent(children); // Declare and initialize here

  const additionalClass = hasText ? "" : "list__item--empty";

  return (
    <li className={classNames("list__item", additionalClass, className)}>
      {children}
    </li>
  );
};

export default ListItem;
