import { HTMLAttributes } from "react";
import s from "./index.module.scss";

export interface DocumentLinkProps extends HTMLAttributes<HTMLDivElement> {
  name: string;
  size: string;
  date: string;
  href: string;
}

function DocumentLink({
  className,
  name,
  size,
  date,
  href,
}: DocumentLinkProps) {
  return (
    <div className={s["document-link"] + " " + className + " row"}>
      <div className={s["document-link__title-container"]}>
        <div>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            className={s["document-link__svg"]}
          >
            <path
              d="M13.7301 10.8302C12.1949 9.86417 11.0395 8.39603 10.4594 6.67414C10.997 5.0633 11.1593 3.35035 10.9338 1.66682C10.8618 1.24269 10.6555 0.853076 10.3455 0.555659C10.0355 0.258243 9.63823 0.0688332 9.21251 0.0154693C8.78678 -0.0378946 8.35523 0.0476241 7.98175 0.259365C7.60827 0.471107 7.3127 0.797816 7.13881 1.19113C6.85456 3.21512 7.06886 5.27811 7.76299 7.19991C6.81473 9.4213 5.75647 11.5938 4.59217 13.7094C2.81951 14.7109 0.3977 16.2131 0.0231934 17.9406C-0.276412 19.3427 2.34513 22.9479 6.81424 15.1365C8.80144 14.3968 10.8369 13.7945 12.9062 13.3339C14.4359 14.2109 16.1422 14.7328 17.8996 14.8611C18.3031 14.8716 18.7007 14.7622 19.0425 14.5468C19.3842 14.3313 19.6548 14.0194 19.8202 13.6501C19.9856 13.2809 20.0385 12.8709 19.9722 12.4716C19.9059 12.0723 19.7234 11.7016 19.4476 11.4061C18.399 10.3295 15.2781 10.6299 13.7301 10.8302ZM1.79586 18.3412C2.49553 17.1405 3.41954 16.0864 4.51727 15.2367C2.81951 17.9406 1.79586 18.4163 1.79586 18.3662V18.3412ZM9.08624 1.29127C9.73539 1.29127 9.68545 4.17048 9.23604 4.94661C8.8975 3.76591 8.84614 2.52103 9.08624 1.31631V1.29127ZM6.91411 13.5091C7.76001 11.9615 8.49425 10.355 9.11121 8.7021C9.77263 9.93634 10.6925 11.0125 11.8077 11.8567C10.1325 12.2775 8.49557 12.8386 6.91411 13.5342V13.5091ZM18.6486 13.0585C18.6486 13.0585 18.1992 13.6093 15.328 12.3574C18.4489 12.1572 18.9732 12.8832 18.6486 13.0835V13.0585Z"
              fill="#EA3323"
            />
          </svg>
        </div>
        <div className={" " + s[`${className}`]}>
          <a
            href={href}
            target="_blank"
            rel="noreferrer"
            className={
              "link link--normal text--secondary label " +
              s["document-link__title"]
            }
          >
            {name}
          </a>
        </div>
      </div>
      <div
        className={
          "label text--secondary row " +
          s["document-link__caption-container"] +
          " " +
          s[`${className}`]
        }
      >
        <span className={s["document-link__caption"] + ""}>{size}</span>
        <span className={s["document-link__caption"] + ""}>{date}</span>
      </div>
    </div>
  );
}

export default DocumentLink;
