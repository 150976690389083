import React, { forwardRef, memo, Ref, RefObject, useMemo, useRef } from "react";
import classNames from "classnames";
import { useEffect } from "react";
import { useImperativeHandle } from "react";
import { SVGAnimateElementExtended } from "types/global";

interface MorphSvgValue {
  from: string;
  to: string;
  duration?: number;
  delay?: number;
  ease?: string;
}

interface MorphSvgControls {
  play: () => void;
}

export interface MorphSvgProps {
  play?: boolean;
  values: MorphSvgValue[];
  duration?: number;
  controlsRef?: RefObject<MorphSvgControls>;
  onBegin?: () => void;
  onEnd?: () => void;


  width: number;
  height: number;
  fill?: string;
  className?: string;
}

const BEZIER_CURVE_DEFAULT = "0 0 1 1";

type MorphSvgRef = SVGSVGElement;

const MorphSvg = ({
  width,
  height,
  values,
  duration,
  onBegin,
  onEnd,
  controlsRef,
  className,
  fill = "currentcolor"
}: MorphSvgProps, ref: Ref<MorphSvgRef>) => {
  const animateElRef = useRef<SVGAnimateElementExtended>(null);
  const totalDuration = useMemo(() => {
    return +values.reduce((acc, cur) => acc + (cur.duration || duration || 0) + (cur.delay || 0), 0).toFixed(5)
  }, [values, duration]);

  const animateAttrs = useMemo(() => {
    const animateValues: string[] = [];
    const keyTimes: number[] = [];
    const keySplines: string[] = [];

    values.forEach((value, i) => {
      const ease = value.ease ? value.ease : BEZIER_CURVE_DEFAULT;
      const itemDelay = (value.delay || 0) / totalDuration;
      const itemDuration = (value.duration || duration || 0) / totalDuration;

      const keyTimeDelayStart = keyTimes[keyTimes.length - 1] || 0;
      const keyTimeStart = +(keyTimeDelayStart + itemDelay).toFixed(4);
      let keyTimeEnd = +(keyTimeStart + itemDuration).toFixed(4);

      if (keyTimeEnd > 1) {
        keyTimeEnd = 1
      }

      if (itemDelay) {
        keySplines.push(BEZIER_CURVE_DEFAULT);
        animateValues.push(value.from);
        keyTimes.push(keyTimeDelayStart);
      }

      keySplines.push(ease);
      animateValues.push(value.from, value.to);
      keyTimes.push(keyTimeStart, keyTimeEnd);

      if (i !== values.length - 1) {
        keySplines.push(BEZIER_CURVE_DEFAULT);
      }
    });

    return {
      values: animateValues.join(";"),
      keyTimes: keyTimes.join(";"),
      keySplines: keySplines.join(";"),
      dur: totalDuration + "s"
    }
  }, [values, totalDuration, duration]);


  useImperativeHandle(controlsRef, () => {
    const animateToEl = animateElRef.current!;

    const play = () => {
      if (animateToEl.beginElement) {
        animateToEl.beginElement();
      }
    }

    return {
      play
    }
  });

  useEffect(() => {
    const animateEl = animateElRef.current!;

    if (onBegin) {
      animateEl.addEventListener("beginEvent", onBegin);
    }

    if (onEnd) {
      animateEl.addEventListener("endEvent", onEnd);
    }

    return () => {
      if (onBegin) {
        animateEl.removeEventListener("beginEvent", onBegin);
      }
      if (onEnd) {

        animateEl.removeEventListener("endEvent", onEnd);
      }
    }
  }, [onBegin, onEnd]);

  return (
    <svg
      ref={ref}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      className={classNames("morph-svg", className)}
    >
      <path d={values[0].from} fill={fill}>
        <animate
          ref={animateElRef}
          attributeName="d"
          fill="freeze"
          begin="indefinite"
          // repeatCount="indefinite"
          calcMode="spline"
          {...animateAttrs}
        />
      </path>
    </svg>
  );
};

export default memo(forwardRef<MorphSvgRef, MorphSvgProps>(MorphSvg));
