import Section from "components/layouts/Section";

import s from "./index.module.scss";
import RevealInView from "components/common/RevealInView";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import ScrollDownButton from "components/layouts/ScrollDownButton";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";
import { useViewServicesItemContext } from "components/views/ViewServicesItem/ViewServicesItem";

function ServicesFaqsSection() {
  const { data } = useViewServicesItemContext();

  return (
    <DynamicBackgroundTrigger>
      <Section
        className={"background--white " + s["view-developer-faqs-section"]}
      >
        <div className={" section-container container"}>
          <div className={"row"}>
            <div
              className={
                "row col-11 col-md-3 col-lg-5 " +
                s["view-developer-faqs-section__header"]
              }
            >
              <RevealInView>
                <h2 className={"heading col-11"}>{data.faqsSection.title}</h2>
              </RevealInView>
            </div>

            <div className=" row col-11 col-md-8  col-lg-6">
              {/* @ts-ignore */}
              {data.faqsSection.items.map((item, index) => {
                return (
                  <div
                    className={
                      s["view-developer-faqs-section__item"] + " col-11 row"
                    }
                    key={index}
                  >
                    <div className="col-11 row">
                      <div className="col-2">
                        <RevealInView>
                          <span className="side-brackets text--secondary caption">
                            {"0" + (index + 1)}
                          </span>
                        </RevealInView>
                      </div>
                      <div className="col-9 row">
                        <div className="col-11">
                          <RevealInView>
                            <p className="side-brackets label">{item.title}</p>
                          </RevealInView>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        "col-9 offset-2 row " +
                        s["view-developer-faqs-section__text-container"]
                      }
                    >
                      <div className={s["view-developer-faqs-section__plus"]}>
                        <RevealInView>
                          <span>+</span>
                        </RevealInView>
                      </div>
                      <div className={s["view-developer-faqs-section__text"]}>
                        <RevealInView>
                          <p>
                            {item.text}
                          </p>
                        </RevealInView>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={s.aunchor}>
              <RevealWhenInView>
                <ScrollDownButton
                  scrollTo=".footer"
                  duration={0.5}
                  className={"scroll-down-button-with-text"}
                >
                  <p>
                    Связаться<br />с нами
                  </p>
                </ScrollDownButton>
              </RevealWhenInView>
            </div>
          </div>
        </div>
      </Section>
    </DynamicBackgroundTrigger>
  );
}

export default ServicesFaqsSection;
