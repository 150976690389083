import ViewServicesBrandingItem from "components/views/ViewServicesItem/ViewServicesBrandingItem";
import { ServicesItemData } from "./types";

const servicesItem: ServicesItemData = {
  id: "branding",
  component: ViewServicesBrandingItem,
  title: "branding",
  name: "branding",

  color: "dark-blue",
  ourExpireanceSection: {
    title: <span>В каких случаях компании необходим брендинг?</span>,
    headText: "Для чего нужна услуга",
    description:
      "мы поможем вам выделиться на рынке, привлечь и удержать клиентов, а также создать положительный имидж и узнаваемость",
    items: [
      { title: "01", description: "Для заявления о себе при выходе на рынок" },
      {
        title: "02",
        description:
          "Для обновления имиджа или изменения позиционирования на рынке",
      },
      {
        title: "03",
        description:
          "Для сохранения идентификации бренда с учетом особенностей менталитета и специфики регионов/стран",
      },
      {
        title: "04",
        description:
          "Для общения с новой целевой аудиторией или создания единого визуального стиля",
      },
    ],
  },
  leadSection: {
    title: "Брендинг",
    description:
      "создаем незабываемые бренды, оставляющие лучшее первое впечатление",
    subdescription: "",
    ourClients: ["sibur", "gazprom", "rosatom", "sberbank", "sk10", "89+"],
    works: [
      {
        title: "делаем",
        items: [
          "логотип",
          "фирменный стиль",
          "рекламные креативы",
          "презентации",
        ],
      },
      {
        title: "",
        items: ["исследования", "ребрендинг", "брендбук / гайдлайны"],
      },
    ],
  },
  sliderSection: [
    {
      title: "MU MU",
      subtitle: "Разработка фирменного стиля для художественного музея мусора",
      headerItems: ["Логотип", "Фирменный стиль"],
      body: {
        title: "Задача",
        items: [
          "проектирование и разработка логотипа",
          "разработка фирменного стиля",
        ],
      },
      logo: "/images/developer-sites/mu-mu/logo",
      background: "/images/developer-sites/mu-mu/background",
      nextSlideButton: <>Следующий проект</>,
    },
    {
      title: "Ensemble",
      subtitle:
        "Разработка сайта и фирменного стиля для нового исламского интернет-банка во Франции",
      headerItems: ["Логотип", "UX|UI дизайн", "Верстка", "Брендинг"],
      body: {
        title: "Задача",
        items: [
          "проектирование и разработка сайта",
          "разработка фирменного стиля",
          "проектирование и разработка логотипа",
        ],
      },
      logo: "/images/developer-sites/ensemble/logo",
      background: "/images/developer-sites/ensemble/background",
      nextSlideButton: (
        <>
          Следующий <br /> проект{" "}
        </>
      ),
    },
    {
      title: "Operation Zero",
      subtitle:
        "Разработка фирменного стиля для платформы для приобретения zero-day эксплоитов",
      headerItems: ["Логотип", "Фирменный стиль"],
      body: {
        title: "Задача",
        items: [
          "проектирование и разработка логотипа",
          "разработка фирменного стиля",
        ],
      },
      logo: "/images/developer-sites/operation-zero/logo",
      background: "/images/developer-sites/operation-zero/background",
      nextSlideButton: (
        <>
          Следующий <br /> проект{" "}
        </>
      ),
    },
    {
      title: "ICR",
      subtitle:
        "Разработка фирменного стиля для промышленной строительной компании",
      headerItems: ["Логотип", "Фирменный стиль"],
      body: {
        title: "Задача",
        items: [
          "проектирование и разработка логотипа",
          "разработка фирменного стиля",
        ],
      },
      logo: "/images/developer-sites/icr/logo",
      background: "/images/developer-sites/icr/background",
      nextSlideButton: (
        <>
          Следующий <br /> проект{" "}
        </>
      ),
    },
  ],

  faqsSection: {
    title: "Faqs",
    items: [
      {
        title: "что включает в себя услуга разработки фирменного стиля?",
        text: "Cоздание логотипа, разработка фирменного стиля, подбор цветовой палитры и шрифтов, создание брендбука и маркетинговых материалов, таких как визитки, брошюры и рекламные макеты",
      },
      {
        title: "Сколько времени занимает процесс разработки фирменного стиля?",
        text: "Процесс разработки бренда может занять от 3 недель в зависимости от сложности проекта и степени вовлеченности клиента. Точные сроки обсуждаются индивидуально.",
      },
      {
        title: "Какие данные нужно предоставить для начала работы?",
        text:
          "Нам потребуется информация о вашей компании, ее миссии, целевой аудитории, конкурентном окружении, а также ваши предпочтения по стилю и цветовой гамме. \n" +
          "Будет полезно, если вы поделитесь примерами брендов, которые вам нравятся.",
      },
      {
        title:
          "Можно ли заказать отдельные элементы брендинга, например, только логотип?",
        text: "Да, вы можете заказать как полный комплекс услуг по брендингу, так и отдельные его элементы, такие как логотип, фирменный стиль или брендбук.",
      },
      {
        title: "Как происходит оплата за услуги?",
        text: "Мы делим работы 2-4 этапа, вы полностью предоплачиваете этап, перед тем как мы к нему приступаем.",
      },
      {
        title: "Получу ли я все исходные файлы после завершения проекта?",
        text: "Да, после завершения проекта вы получите все исходные файлы в нужных форматах для дальнейшего использования и печати.",
      },
    ],
  },

  workStagesSection: {
    title: "ЭТАПЫ РАБОТЫ",
    description:
      "Отлаженный процесс работы команды для комфортного сотрудничества и безупречного результата",
    items: [
      {
        tickerText: "ПЕРВЫЙ ЭТАП",
        subtitle: "включение в проект",
        max_step_number: 7,
        items: [
          {
            title: "Брифинг",
            subtitle: "",
            step_number: 1,
            text: "начальный этап, на котором мы встречаемся с вами, чтобы понять ваши потребности и ожидания от поддержки. Мы обсуждаем основные задачи и цели проекта",
          },
          {
            title: "Исследование",
            subtitle: "",
            step_number: 2,
            text: "на этом этапе мы проводим анализ вашей отрасли, целевой аудитории и конкурентов. Исследуем актуальные тренды и лучшие практики, чтобы создать уникальный и запоминающийся бренд, который выделится на рынке",
          },
        ],
      },
      {
        tickerText: "второй этап",
        subtitle: "работа над брендингом",
        max_step_number: 7,
        items: [
          {
            title: "Референсы",
            subtitle: "",
            step_number: 3,
            text: "С помощью референсов для логотипа и/или фирменного стиля мы определяем общее направление стилистики и фиксируем ключевые идеи. Это позволяет убедиться, что мы правильно понимаем концепцию бренда и мыслим в одном направлении с вами",
          },
          {
            title: "Разработка лого",
            subtitle: "с примерами использования",
            step_number: 4,
            text: "Создаем логотип, который отражает уникальность вашего бренда. Разрабатываем примеры использования логотипа в различных контекстах: на визитках, бланках, упаковке, веб-сайтах и других носителях, чтобы показать его универсальность и адаптивность",
          },
          {
            title: "Элементы фир. стиля",
            subtitle: "цвета, шрифты, элементы фирменного стиля",
            step_number: 5,
            text: "Подбираем цветовую палитру и шрифтовые сочетания. Разрабатываем паттерны, графические элементы и иллюстрации. разрабатываем элементы фирменного стиля и дизайна других поверхностей. Определяем стилистику фотографий, создаем фотофильтры-пресеты и составляем правила выбора фотографий и других изображений",
          },
        ],
      },
      {
        tickerText: "третий этап",
        subtitle: "завершение проекта",
        max_step_number: 7,
        items: [
          {
            title: "Гайдлайны",
            subtitle: "Разработка гайдлайна",
            step_number: 6,
            text: "Гайдлайн помогает вам адаптировать фирменный стиль для различных маркетинговых кампаний. В нем изложены правила оформления и использования на разных типах носителей, требования к размерам и другие принципы, которые можно легко и быстро применить",
          },
          {
            title: "Передача результатов",
            subtitle: "сборка архива материалов",
            step_number: 7,
            text: "Мы отдаем вам исходники всех утвержденных версий элементов фирменного стиля, стилеобразующих элементов и подготовленный гайдлайн в формате pdf",
          },
        ],
      },
    ],
  },
  customerRewiewsSection: {
    title: "отзывы клиентов",
    subtitle:
      "Мы ответственны в своём деле и всегда нацелены на достижение лучшего результата",
    reviews: [
      {
        title: "Мартынович Михаил",
        subtitle: "руководитель ИТ департамента - застройщик ооо СК10",
        point: 5,
        text: "Команда студии SAGIROV.com показала себя надежным подрядчиком, ответственно, оперативно и квалифицированно выполняющим работы по проектированию, сопровождению и обслуживанию сайта. Мы очень довольны результатами проделанной работы и надеемся на дальнейшее совместное сотрудничество.",
        icon: "sk10-paginator.svg",
      },

      {
        title: "Георги Атоян",
        subtitle: "Генеральный директор Дома-Века",
        point: 5,
        text: "Нам очень нравится результат, который мы получили. Мы давно хотели для себя сайт, который не будет похож на другие, типовые сайты из нашей отрасли. Нашим клиентам он тоже очень нравится. Самое главное, что он получился информативным, современным и понятным. Многие наши конкуренты тоже переделали свои сайты и позаимствовали у нас много идей.",
        icon: "doma-veka-paginator.svg",
      },
      {
        title: "Ильин Игорь",
        subtitle: "Учередитель ООО Новация",
        point: 5,
        text: "Выражаем благодарность Студии Микаэла Сагирова за разработку сайт для нашей компании - быстро и качественно, в процессе работы оперативно вносили правки. Подсказывали как будет лучше и какие вещи нам не подходят на основе аналитики и опыта. По итогу работы разработали дизайн, сайт, выполнили ряд интеграций. Работой довольны, готовы рекомендовать Студию как надежного подрядчика.",
        icon: "nova-group-paginator.svg",
      },
      {
        title: "Екатерина Цыганкова",
        subtitle: "Маркетолог Корпорации СКЭСС",
        point: 5,
        text: "На протяжении сотрудничества команда Студии SAGIROV.com демонстрирует высокий профессионализм, гибкость и быстрое реагирование в решении поставленных задач по поддержке и техническому обслуживанию сайта, индивидуальный подход в решении поставленных задач, а также их своевременное выполнение. Нам удалось построить эффективную коммуникацию, дружеские и доверительные отношения.",
        icon: "skess-paginator.svg",
      },
    ],

    footerText: (
      <>
        следующий <br /> отзыв
      </>
    ),
  },

  priceSection: {
    title: "Цены",
    subtitle:
      "Ценообразование всегда максимально прозрачно и рассчитывается по простой формуле",
    items: [
      {
        type: "price",
        title: "Стоимость",
        aunchor: {
          to: ".footer",
          // @ts-ignore
          text: (
            <>
              заказать расчет <br /> стоимости проекта
            </>
          ),
        },
        caption:
          "Рассчитывается на основе трудозатрат на отдельный вид работ и зависит от количества кругов правок, концепций, объема работ и профиля привлекаемых специалистов",
        items: [
          {
            title1: "Логотип",
            caption1: "",
            title2: "от 250 000 р.",
            caption2: "от 15 до 20 дней",
            isCross: false,
          },
          {
            title1: "Фирменный стиль",
            caption1: "",
            title2: "от 500 000 р.",
            caption2: "от 15 до 20 дней",
            isCross: false,
          },
          {
            title1: "Брендбук / Гайдлайны",
            caption1: "",
            title2: "от 200 000 р.",
            caption2: "от 15 до 20 дней",
            isCross: false,
          },
        ],
      },
    ],
  },

  jobTypes: {
    title: "УСЛУГИ",
    subtitle:
      "Мы создаем брендинг вместе или отдельно с цифровой продукцией, делаем рекомендации по фирменному стилю, сопровождаем в печать",
    items: [
      {
        title: "",
        caption: [
          "визитные карточки",
          "бланки и конверты",
          "презентационные материалы",
          "упаковка продукции",
          "рекламные материалы",
          "сайт и мобильные приложения",
          "соцсети",
          "корпоративная одежда и аксессуары",
          "вывески и интерьер",
          "мерч",
          "транспорт",
          "и т.д.",
        ],
        items: [
          {
            type: "titled-list",
            title: "делаем",
            items: [
              "разработка логотипа",
              "разработка фирменного стиля",
              "редизайн логотипа и фирменного стиля",
            ],
          },
          {
            type: "titled-list",
            title: "предоставляем",
            items: [
              "гайдлайн в формате презентации и для редактирования",
              "все версии логотипа",
              "пакет лицензированных шрифтов",
              "иллюстрации, паттерны и графические элементы",
              "фильтры и пресеты",
              "анимированные объекты",
              "3D-модели",
            ],
          },
        ],
      },
    ],
  },
  seo: {
    title: "Брендинг и разработка фирменного стиля | SAGIROV",
    description:
        "Создаем запоминающийся бренд, формируем уникальный визуальный стиль и разрабатываем ключевые элементы айдентики — логотип, брендбук, гайдлайны.",
    keywords: [
      "брендинг",
      "разработка бренда",
      "фирменный стиль",
      "дизайн логотипа",
      "брендбук",
      "бренд айдентика",
      "бренд стратегия",
    ],
    openGraph: {
      type: "article",
      title: "Брендинг и разработка фирменного стиля | SAGIROV",
      description:
          "Создаем запоминающийся бренд, формируем уникальный визуальный стиль и разрабатываем ключевые элементы айдентики — логотип, брендбук, гайдлайны.",
    },
  },
};

export default servicesItem;
