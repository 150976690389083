import ViewServicesBrandingItem from "components/views/ViewServicesItem/ViewServicesBrandingItem";
import { ServicesItemData } from "./types";

const servicesItem: ServicesItemData = {
  id: "outstaffing",
  component: ViewServicesBrandingItem,
  title: "outstaffing",
  name: "Outstaffing",

  color: "light-blue",
  ourExpireanceSection: {
    title: (
      <span className="heading-2">
        <i className="heading italic-text">плюсы аутстаффинга&#160;—</i> почему
        аутстаффинг лучше и выгоднее, чем аутсорсинг
      </span>
    ),
    headText: "преимущества",
    description: "aunchor",
    items: [
      {
        title: "Гибкость и масштабируемость",
        description:
          "Подбор команды в зависимости от текущих потребностей проекта",
      },
      {
        title: "Снижение затрат",
        description:
          "Экономия на найме, обучении и содержании штатных сотрудников",
      },
      {
        title: "Снижение рисков",
        description:
          "Текучестью кадров и юридические аспекты трудовых отношений",
      },
      {
        title: "фокус на бизнесе",
        description:
          "Освобождение времени для концентрации на стратегических задачах",
      },
      {
        title: "доступ к редкой экспертности",
        description:
          "Быстрое привлечение экспертов с необходимыми навыками и  опытом",
      },
    ],
  },
  leadSection: {
    title: "Аут<br>стаффинг",
    description: "оперативное подключение IT-специалистов на ваш проект",
    subdescription: "",
    ourClients: ["sibur", "gazprom", "rosatom", "sberbank", "sk10", "89+"],
    works: [
      {
        title: "Помогаем",
        items: ["срочно усилить команду"],
      },
      {
        title: "",
        items: ["найти сотрудника с нужной вам компетенцией"],
      },
      {
        title: "",
        items: ["Арендовать слаженную команду профессионалов"],
      },
    ],
  },
  sliderSection: [],

  faqsSection: {
    title: "Faqs",
    items: [
      {
        title: "Кому подходит аутстаффинг?",
        text: "Аутстаффинг идеален для компаний, которые хотят сэкономить время и ресурсы на поиске, найме и обучении сотрудников, и нуждаются в опытных специалистах для конкретных проектов",
      },
      {
        title:
          "Заключаете ли вы договор аутстаффинга и есть ли у вас лицензия?",
        text: "Нет, мы заключаем договор на разработку IT-продукта по модели Time&Material. Это не аутстаффинг, или не заемный труд, с юридической точки зрения.",
      },
      {
        title: "Как работает аутстаффинг персонала?",
        text: "Вы оставляете заявку, наш менеджер проводит детальный брифинг ваших потребностей и подбираем необходимого вам квалифицированного специалиста . При необходимости он может пройти  техническое собеседование. Мы подписываем договор, и специалист начинает работу.",
      },
      {
        title: "Есть ли у вас тестовый формат работы?",
        text: "Тестовый период до 5 дней. Если специалист не подойдет, то эти часы не будут оплачены.",
      },
      {
        title: "Нужно ли нам оплачивать специалистам отпуска и больничные?",
        text: "Нет, все эти расходы покрываем мы.",
      },
      {
        title: "Нужно ли выделять специалисту рабочее место?",
        text: "Нет, специалист работает у нас в офисе и на нашем оборудовании. Однако, если у вас есть требования безопасности, сотрудник может использовать ваше оборудование и работать в вашем офисе.",
      },
      {
        title: "Как быстро можно подобрать необходимого специалиста?",
        text: "В течение 48 часов.",
      },
    ],
  },

  workStagesSection: {
    title: "ЭТАПЫ РАБОТЫ",
    description:
      "Алгоритм интеграции наших специалистов в вашу команду для комфортного сотрудничества         и безупречного результата",
    items: [
      {
        tickerText: "ПЕРВЫЙ ЭТАП",
        subtitle: "подготовка и подбор",
        max_step_number: 5,
        items: [
          {
            title: "Анализ",
            subtitle: "",
            step_number: 1,
            text: "Проведение анализа проекта, включая его специфику, используемые технологии и ожидаемые результаты. Определение требований к квалификации и опыту необходимых специалистов",
          },
          {
            title: "Поиск",
            subtitle: "кандидатов",
            step_number: 2,
            text: "подбираем специалистов и предоставляем вам резюме кандидатов для предварительного ознакомления и оценки",
          },
        ],
      },
      {
        tickerText: "второй этап",
        subtitle: "работа над проектом",
        max_step_number: 5,
        items: [
          {
            title: "Подключение",
            subtitle: "",
            step_number: 3,
            text: "Быстро вводим специалиста на ваш проект с адаптационным периодом не более 3 дней. Обеспечиваем его необходимыми инструментами для оперативного начала работы",
          },
          {
            title: "Процесс",
            subtitle: "работы",
            step_number: 4,
            text: "осуществляем поддержку на всем жизненном цикле работы специалиста на вашем проекте. Регулярно оцениваем его производительность, предоставляем обратную связь и помогаем в решении возникающих вопросов, чтобы обеспечить высокую эффективность работы",
          },
          {
            title: "Завершение",
            subtitle: "проекта",
            step_number: 5,
            text: "Координируем завершение работы специалиста на вашем проекте, обеспечиваем передачу всех необходимых знаний и документации вашей внутренней команде. Проводим оценку итогов работы и собираем отзывы для улучшения будущих процессов",
          },
        ],
      },
    ],
  },
  customerRewiewsSection: {
    title: "отзывы клиентов",
    subtitle:
      "Мы ответственны в своём деле и всегда нацелены на достижение лучшего результата",
    color: "dark-white-2",
    reviews: [
      {
        title: "Мартынович Михаил",
        subtitle: "руководитель ИТ департамента - застройщик ооо СК10",
        point: 5,
        text: "Команда студии SAGIROV.com показала себя надежным подрядчиком, ответственно, оперативно и квалифицированно выполняющим работы по проектированию, сопровождению и обслуживанию сайта. Мы очень довольны результатами проделанной работы и надеемся на дальнейшее совместное сотрудничество.",
        icon: "sk10-paginator.svg",
      },

      {
        title: "Георги Атоян",
        subtitle: "Генеральный директор Дома-Века",
        point: 5,
        text: "Нам очень нравится результат, который мы получили. Мы давно хотели для себя сайт, который не будет похож на другие, типовые сайты из нашей отрасли. Нашим клиентам он тоже очень нравится. Самое главное, что он получился информативным, современным и понятным. Многие наши конкуренты тоже переделали свои сайты и позаимствовали у нас много идей.",
        icon: "doma-veka-paginator.svg",
      },
      {
        title: "Ильин Игорь",
        subtitle: "Учередитель ООО Новация",
        point: 5,
        text: "Выражаем благодарность Студии Микаэла Сагирова за разработку сайт для нашей компании - быстро и качественно, в процессе работы оперативно вносили правки. Подсказывали как будет лучше и какие вещи нам не подходят на основе аналитики и опыта. По итогу работы разработали дизайн, сайт, выполнили ряд интеграций. Работой довольны, готовы рекомендовать Студию как надежного подрядчика.",
        icon: "nova-group-paginator.svg",
      },
      {
        title: "Екатерина Цыганкова",
        subtitle: "Маркетолог Корпорации СКЭСС",
        point: 5,
        text: "На протяжении сотрудничества команда Студии SAGIROV.com демонстрирует высокий профессионализм, гибкость и быстрое реагирование в решении поставленных задач по поддержке и техническому обслуживанию сайта, индивидуальный подход в решении поставленных задач, а также их своевременное выполнение. Нам удалось построить эффективную коммуникацию, дружеские и доверительные отношения.",
        icon: "skess-paginator.svg",
      },
    ],

    footerText: (
      <>
        следующий <br /> отзыв
      </>
    ),
  },

  priceSection: {
    title: "Цены",
    subtitle:
      "Ценообразование всегда максимально прозрачно и рассчитывается по простой формуле",
    dopclasses: "",
    items: [
      {
        type: "price",
        title: "Веб-разработка",
        caption: "front-end и back-end",
        link: {
          text: "подробнее об услуге",
          href: "/services/website-development",
        },

        items: [
          {
            title1: "Базовая цена",
            caption1: "",
            title2: "от 1800 р.",
            caption2: "стоимость часа",
            isCross: false,
          },
          {
            title1: "",
            caption1: "",
            title2: "от\u00a0288\u00a0000\u00a0р.",
            caption2: "стоимость в месяц",
            isCross: false,
          },
        ],
      },
      {
        type: "price",
        title: "Front-end",
        caption: "JavaScript (Vue.js, Nuxt.js, React.js, TypeScript)",
        size: "h4",
        items: [
          {
            title1: "Front-end middle",
            caption1: "",
            title2: "от 1800 р.",
            caption2: "стоимость часа",
            isCross: false,
          },
          {
            title1: "",
            caption1: "",
            title2: "от\u00a0288\u00a0000\u00a0р.",
            caption2: "стоимость в месяц",
            isCross: false,
          },

          {
            title1: "Front-end middle+ / senior",
            caption1: "",
            title2: "от 2200 р.",
            caption2: "стоимость часа",
            isCross: false,
          },
          {
            title1: "",
            caption1: "",
            title2: "от\u00a0416\u00a0000\u00a0р.",
            caption2: "стоимость в месяц",
            isCross: false,
          },

          {
            title1: "Front-end teamlead",
            caption1: "",
            title2: "от 3000 р.",
            caption2: "стоимость часа",
            isCross: false,
          },
          {
            title1: "",
            caption1: "",
            title2: "от\u00a0480\u00a0000\u00a0р.",
            caption2: "стоимость в месяц",
            isCross: false,
          },
        ],
      },
      {
        type: "price",
        title: "Back-end",
        caption: "PHP, Java, Python, Node.js",
        size: "h4",
        items: [
          {
            title1: "Back-end middle",
            caption1: "",
            title2: "от 1800 р.",
            caption2: "стоимость часа",
            isCross: false,
          },
          {
            title1: "",
            caption1: "",
            title2: "от\u00a0400\u00a0000\u00a0р.",
            caption2: "стоимость в месяц",
            isCross: false,
          },

          {
            title1: "Back-end middle+ / senior",
            caption1: "",
            title2: "от 2300 р.",
            caption2: "стоимость часа",
            isCross: false,
          },
          {
            title1: "",
            caption1: "",
            title2: "от\u00a0448\u00a0000\u00a0р.",
            caption2: "стоимость в месяц",
            isCross: false,
          },
          {
            title1: "Back-end teamlead",
            caption1: "",
            title2: "от 3500 р.",
            caption2: "стоимость часа",
            isCross: false,
          },
          {
            title1: "",
            caption1: "",
            title2: "от\u00a0560\u00a0000\u00a0р.",
            caption2: "стоимость в месяц",
            isCross: false,
          },
        ],
      },

      {
        type: "price",
        title: "Дизайн",
        caption: "web, mobile, маркетинг",
        link: {
          text: "подробнее об услуге",
          href: "/services/design",
        },

        items: [
          {
            title1: "Базовая цена",
            caption1: "",
            title2: "от 2300 р.",
            caption2: "стоимость часа",
            isCross: false,
          },
          {
            title1: "",
            caption1: "",
            title2: "от\u00a0368\u00a0000\u00a0р.",
            caption2: "стоимость в месяц",
            isCross: false,
          },
        ],
      },
      {
        type: "price",
        title: "Управление проектом",
        caption: "",
        // link: {
        // text: "подробнее об услуге",
        // href: "/services/technicalSupport",
        // },

        items: [
          {
            title1: "Базовая цена",
            caption1: "",
            title2: "от 2200 р.",
            caption2: "стоимость часа",
            isCross: false,
          },
          {
            title1: "",
            caption1: "",
            title2: "от\u00a0352\u00a0000\u00a0р.",
            caption2: "стоимость в месяц",
            isCross: false,
          },
        ],
      },
    ],
  },
  jobTypes: {
    title: "инструменты и\xa0Технологии",
    subtitle:
      "Обеспечиваем бесперебойную работу сайта, оперативное обновление контента и развитие функционала в кратчайшие сроки",
    items: [
      {
        title: "Веб-разработка",
        caption: ["сайты", "хостинги и сервера", "работа с функционалом"],
        items: [
          {
            type: "double-titled-list",
            title: "ТЕХНОЛОГИИ",
            subtitle1: "Front-end",
            subtitle2: "Back-end",
            items: [
              "react js",
              "django",
              "next js",
              "python",
              "vue js",
              "node js",
              "nuxt js",
              "php",
              "html/js/css",
              "laravel",
            ],
          },
          {
            type: "double-titled-list",
            title: "cms",

            items: [
              "1с битрикс",
              "joomla",
              "modx",
              "tilda",
              "wordpress",
              "webflow",
              "opencart",
              "readymag",
              "preashop",
              "wix",
            ],
          },
          {
            type: "titled-list",
            title: "интеграции",

            items: [
              "bitrix 24",
              "amocrm",
              "1c-фитнес",
              "roistat",
              "comagic",
              "mango",
              "и др.",
            ],
          },
        ],
      },
      {
        title: "Дизайн",
        caption: ["ux/ui дизайн", "графический дизайн", "визуальные эффекты"],
        items: [
          {
            type: "list",
            title: "делаем",

            items: [
              "брендинг",
              "сайты",
              "modx",
              "приложения",
              "интерфейсы",
              "визуальные эффекты",
            ],
          },
          {
            type: "titled-list",
            title: "инструменты",

            items: [
              "figma",
              "photoshop",
              "illustrator",
              "after effects",
              "indesign",
            ],
          },
        ],
      },
    ],
  },
  seo: {
    title: "Аутстаффинг IT-специалистов | SAGIROV",
    description:
        "Предлагаем услуги аутстаффинга квалифицированных веб-разработчиков, дизайнеров и проектных менеджеров. Расширяйте команду без лишних затрат и бюрократии.",
    keywords: [
      "аутстаффинг",
      "аутстаффинг it",
      "удаленные разработчики",
      "аутсорсинг персонала",
      "аренда IT-специалистов",
      "расширение команды",
      "веб-разработчики на аутстаффе",
      "аутстафф дизайнеров",
      "аутстафф разработчиков",
    ],
    openGraph: {
      type: "article",
      title: "Аутстаффинг IT-специалистов | SAGIROV",
      description:
          "Предлагаем услуги аутстаффинга квалифицированных веб-разработчиков, дизайнеров и проектных менеджеров. Расширяйте команду без лишних затрат и бюрократии.",
    },
  },
};

export default servicesItem;
