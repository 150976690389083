import Section from "components/layouts/Section";
import { Link, NavLink } from "react-router-dom";
import Icon from "components/common/Icon";
import { useTranslation } from "react-i18next";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";

function ViewServicesListSection() {

  const {t} = useTranslation()
  return (
    <DynamicBackgroundTrigger>
      <Section className="view-services-services-section row">
        {(t("view:services.section.servicesList", {returnObjects: true}) as any[]).map((item, index) => {
          return (
            <NavLink
              to={item.link}
              className="view-services-services-section__link-item row col-11"
            >
              <h4 className="col-md-4 col-11 subheading">{item.title}</h4>
              <div className="col-md-5 col-10 offset-md-1 offset-0 description-container">
                <h6 className="heading-6">{item.description}</h6>
                <div className="row subitem-container">
                  {/* @ts-ignore */}
                  {item.items.map((option, optionIndex) => {
                    return <p className="subitem footnote">{option}</p>;
                  })}
                </div>
              </div>
              <div className="col-1 arrow-link">
                <Icon name="arrow-down" className="link_arrow" />
              </div>
            </NavLink>
          );
        })}
      </Section>
    </DynamicBackgroundTrigger>
  );
}

export default ViewServicesListSection;
