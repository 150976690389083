import { ServicesItemData } from "./types";
import ViewServicestechnicalSupportItem from "components/views/ViewServicesItem/ViewServicestechnicalSupportItem";

const servicesItem: ServicesItemData = {
  id: "technical-support",
  component: <ViewServicestechnicalSupportItem />,
  componentPlace: 1,
  title: "technical-support",
  name: "Technical Support",

  color: "orange",
  ourExpireanceSection: {
    title: (
      <span className="heading-2">
        <i className="heading italic-text">Подключаемся&#160;—</i> к любой системе
        учета задач при необходимости
      </span>
    ),
    headText: "системы учета задач",
    description: "aunchor",
    items: [
      {
        title: "Рабочие чаты",
        description:
          "Мы общаемся в общих рабочих чатах, где быстро фиксируем все задачи и проблемы и сразу передаем их в разработку",
      },
      {
        title: "Тестовые версии",
        description:
          "Любые изменения сначала делаются в тестовых версиях и только после проверки переносятся на основной продукт",
      },
      {
        title: "Использование  Bitrix24",
        description: "Для управления проектами используем Bitrix24",
      },
    ],
  },
  leadSection: {
    title: "Тех<br>поддержка",
    description: "поддержка и развитие сайтов на CMS и современных фреймворках",
    subdescription: "сведем ваши проблемы \n с сайтом к нулю",
    ourClients: ["sibur", "gazprom", "rosatom", "sberbank", "sk10", "89+"],
    works: [
      {
        title: "ОПЕРАТИВНО",
        items: ["15 МИНУТ — гарантированное время реакции на обращение"],
      },
      {
        title: "прозрачно",
        items: ["ОПЛАТА ТОЛЬКО ЗА ФАКТИЧЕСКОЕ ВРЕМЯ"],
      },
    ],
  },
  sliderSection: [
    {
      title: "SK10",
      subtitle: "Поддержка проекта с 2019 года по Н.В.",
      headerItems: [
        "тех поддержка",
        "UX/UI дизайн",
        "Разработка сайта",
        "Разработка функционала",
      ],
      body: {
        title: "Задача",
        items: [
          "проектирование и разработка сайта",
          "техническая поддержка сайта",
          "Дизайн и разработка новых разделов",
        ],
      },
      logo: "/images/developer-sites/sk10/logo",
      background: "/images/developer-sites/sk10/background",
      nextSlideButton: <>Следующий проект</>,
    },
    {
      title: "AAG",
      subtitle: "Разработка и поддержка проекта с 2023 года по 2024 год",
      headerItems: ["Тех поддержка", "UI/UX дизайн", "Разработка функционала"],
      body: {
        title: "Задача",
        items: [
          "техническая поддержка сайта",
          "Дизайн и разработка новых разделов",
        ],
      },
      logo: "/images/developer-sites/aag/logo",
      background: "/images/developer-sites/aag/background",
      nextSlideButton: (
        <>
          Следующий <br /> проект{" "}
        </>
      ),
    },
    {
      title: "Дома века",
      subtitle: "Разработка и поддержка проекта с 2018 года по Н.В.",
      headerItems: [
        "разработка сайта",
        "UI/UX дизайн",
        "Тех поддержка",
        "Разработка функционала",
      ],
      body: {
        title: "Задача",
        items: [
          "проектирование и разработка сайта",
          "техническая поддержка сайта",
          "Дизайн и разработка новых разделов",
        ],
      },
      logo: "/images/developer-sites/doma-veka/logo",
      background: "/images/developer-sites/doma-veka/background",
      nextSlideButton: (
          <>
            Следующий <br /> проект{" "}
          </>
      ),
    },
    {
      title: "Новация",
      subtitle: "Разработка и поддержка проекта с 2014 года по Н.В.",
      headerItems: [
        "разработка сайта",
        "UI/UX дизайн",
        "Тех поддержка",
        "Разработка функционала",
      ],
      body: {
        title: "Задача",
        items: [
          "проектирование и разработка сайта",
          "техническая поддержка сайта",
          "Дизайн и разработка новых разделов",
        ],
      },
      logo: "/images/developer-sites/nova-group/logo",
      background: "/images/developer-sites/nova-group/background",
      nextSlideButton: (
          <>
            Следующий <br /> проект{" "}
          </>
      ),
    },
  ],

  faqsSection: {
    title: "Faqs",
    items: [
      {
        title:
          "Что включает в себя услуга технической поддержки и обслуживания сайтов?",
        text: (
          <>
            Расширение функционала сайта
            <br />
            Доработки по дизайну и UX/UI <br />
            Обновление контента <br />
            Лечение от вирусов <br />
            Увеличение скорости загрузки <br />
            Защиту от вирусов и хакерских атак <br />
            Мониторинг работоспособности сайта <br />
            Исправление ошибок и багов <br />
            Регулярное обновление системы и плагинов <br />
            Резервное копирование данных
            <br />
            Оптимизацию производительности <br />
          </>
        ),
      },
      {
        title: "Как часто проводится техническое обслуживание сайта?",
        text: "Регулярное техническое обслуживание проводится ежемесячно. Расширение функционала и доработки проводятся по запросу. Если возникают срочные проблемы, мы оперативно их решаем в любое время. ",
      },
      {
        title: "Какие данные необходимы для начала работы?",
        text: "Нам потребуются доступы к администраторской панели вашего сайта, хостингу и домену, а также информация о текущих проблемах и пожеланиях по улучшению функционала или дизайна.",
      },
      {
        title:
          "Сколько времени занимает решение технических проблем и доработок?",
        text: (
          <>
            Время решения зависит от сложности задачи. Мы стараемся решать все
            критические проблемы максимально оперативно. <br />
            <br /> Мелкие задачи и доработки по дизайну обычно выполняются в
            течение нескольких часов, а более крупные изменения могут занять
            несколько дней.
          </>
        ),
      },
      {
        title: "Что делать, если сайт взломан или заражен вирусом?",
        text: "Если ваш сайт взломан или заражен, свяжитесь с нами как можно скорее. Мы проведем диагностику, удалим вредоносное ПО и восстановим работоспособность сайта.",
      },
      {
        title: "Какие гарантии вы предоставляете на свои услуги?",
        text: "Мы гарантируем, что ваш сайт будет работать стабильно и безопасно. В случае любых проблем мы оперативно устраним их в рамках договора о технической поддержке.",
      },
      {
        title: "Как происходит оплата за услуги технической поддержки?",
        text: "Оплата производится ежемесячно. Возможна также оплата за разовые услуги и доработки.",
      },
      {
        title: "Предоставляете ли вы отчеты о проделанной работе?",
        text: "Да, мы предоставляем подробные отчеты о выполненных работах, обновлениях, устраненных проблемах, а также о реализованных доработках и улучшениях. Отчеты можно получать ежемесячно или по запросу.",
      },
    ],
  },

  workStagesSection: {
    title: "ЭТАПЫ РАБОТЫ",
    description:
      "Настраиваем рабочий процесс для комфортного взаимодействия с командой поддержки",
    items: [
      {
        tickerText: "ПЕРВЫЙ ЭТАП",
        subtitle: "включение в проект",
        max_step_number: 7,
        items: [
          {
            title: "Брифинг",
            subtitle: "",
            step_number: 1,
            text: "начальный этап, на котором мы встречаемся с вами, чтобы понять ваши потребности и ожидания от поддержки. Мы обсуждаем основные задачи и цели проекта",
          },
          {
            title: "Диагностика",
            subtitle: "",
            step_number: 2,
            text: "После анализа потребностей, мы проводим диагностику проблемы, анализируем возможные воздействия на ваш бизнес. На основе полученных данных разрабатываем детальный план действий",
          },
        ],
      },
      {
        tickerText: "второй этап",
        subtitle: "внедрение и поддержка",
        max_step_number: 7,
        items: [
          {
            title: "Настройка",
            subtitle: "систем",
            step_number: 3,
            text: "Внедряем наши системы и инструменты в вашу инфраструктуру. Проводим начальную проверку и настройку, чтобы убедиться, что все системы работают корректно и готовы к оперативной поддержке",
          },
          {
            title: "Обеспечение",
            subtitle: "техподдержки",
            step_number: 4,
            text: "Обеспечиваем круглосуточную техническую поддержку для решения текущих проблем и вопросов. Наши специалисты готовы быстро реагировать на запросы, устранять неполадки и обеспечивать бесперебойную работу ваших систем",
          },
          {
            title: "Профилактика",
            subtitle: "",
            step_number: 5,
            text: "Проводим регулярное профилактическое обслуживание ваших систем и серверов. Выполняем обновления, проверяем безопасность, оптимизируем производительность и предотвращаем возможные проблемы",
          },
        ],
      },
      {
        tickerText: "третий этап",
        subtitle: "оптимизация и контроль качества",
        max_step_number: 7,
        items: [
          {
            title: "Отчеты",
            subtitle: "и рекомендации",
            step_number: 6,
            text: "Регулярно предоставляем отчеты о состоянии ваших систем, выполненных работах и выявленных проблемах. Даем рекомендации по улучшению работы и повышению безопасности ваших систем, чтобы обеспечить их стабильную работу в будущем",
          },
          {
            title: "Модернизация",
            subtitle: "",
            step_number: 7,
            text: "Постоянно следим за новыми технологиями и решениями в области техподдержки. При необходимости, предлагаем интеграцию новых инструментов и систем, чтобы улучшить производительность и безопасность ваших IT-инфраструктур",
          },
        ],
      },
    ],
  },
  customerRewiewsSection: {
    title: "отзывы клиентов",
    subtitle:
      "Мы ответственны в своём деле и всегда нацелены на достижение лучшего результата",
    reviews: [
      {
        title: "Мартынович Михаил",
        subtitle: "руководитель ИТ департамента - застройщик ооо СК10",
        point: 5,
        text: "Команда студии SAGIROV.com показала себя надежным подрядчиком, ответственно, оперативно и квалифицированно выполняющим работы по проектированию, сопровождению и обслуживанию сайта. Мы очень довольны результатами проделанной работы и надеемся на дальнейшее совместное сотрудничество.",
        icon: "sk10-paginator.svg",
      },

      {
        title: "Георги Атоян",
        subtitle: "Генеральный директор Дома-Века",
        point: 5,
        text: "Нам очень нравится результат, который мы получили. Мы давно хотели для себя сайт, который не будет похож на другие, типовые сайты из нашей отрасли. Нашим клиентам он тоже очень нравится. Самое главное, что он получился информативным, современным и понятным. Многие наши конкуренты тоже переделали свои сайты и позаимствовали у нас много идей.",
        icon: "doma-veka-paginator.svg",
      },
      {
        title: "Ильин Игорь",
        subtitle: "Учередитель ООО Новация",
        point: 5,
        text: "Выражаем благодарность Студии Микаэла Сагирова за разработку сайт для нашей компании - быстро и качественно, в процессе работы оперативно вносили правки. Подсказывали как будет лучше и какие вещи нам не подходят на основе аналитики и опыта. По итогу работы разработали дизайн, сайт, выполнили ряд интеграций. Работой довольны, готовы рекомендовать Студию как надежного подрядчика.",
        icon: "nova-group-paginator.svg",
      },
      {
        title: "Екатерина Цыганкова",
        subtitle: "Маркетолог Корпорации СКЭСС",
        point: 5,
        text: "На протяжении сотрудничества команда Студии SAGIROV.co демонстрирует высокий профессионализм, гибкость и быстрое реагирование в решении поставленных задач по поддержке и техническому обслуживанию сайта, индивидуальный подход в решении поставленных задач, а также их своевременное выполнение. Нам удалось построить эффективную коммуникацию, дружеские и доверительные отношения.",
        icon: "skess-paginator.svg",
      },
    ],

    footerText: (
      <>
        следующий <br /> отзыв
      </>
    ),
  },

  priceSection: {
    title: "Цены",
    subtitle:
      "Ценообразование всегда максимально прозрачно и рассчитывается по простой формуле",
    dopclasses: "servicepage-v2",
    items: [
      {
        type: "price",
        title: "Простая формула расчета",
        caption: "рассчитаем честную цену по принципу Time&Material",
        isCross: true,
        items: [
          {
            title1: "Кол-во часов",
            caption1:
              "это затраченное время на решение задач в рамках договора",
            title2: "1800₽/час",
            caption2: "от 50 часов в месяц",
            isCross: true,
            isSmall: false,
          },
          {
            title1: "",
            caption1: "",
            title2: "2000₽/час",
            caption2: "до 50 часов в месяц",
            isSmall: true,
          },
          {
            title1: "",
            caption1: "",
            title2: "2300₽/час",
            caption2: "до 20 часов в месяц",
            isSmall: true,
          },
        ],
      },
      {
        type: "formats",
        title: "Прозрачная отчетность",
        caption: "",
        items: [
          {
            title: "Доступ к time трекеру",
            subtitle:
              "Вы получаете полный прозрачный доступ ко всем отчетам о времени и задачах",
          },
          {
            title: "ежемесячный отчет",
            subtitle:
              "Каждый месяц мы формируем для вас детальный отчет о проденной работе",
          },
          {
            document: {
              name: "пример отчета за месяц ",
              size: "592 Кб",
              date: "отчет за апрель",
              href: "#",
            },
          },
        ],
      },
      {
        type: "formats",
        title: "Форматы оплаты",
        caption: "",
        items: [
          {
            title: "Time material",
            subtitle:
              "Этот формат предоставляет максимальную гибкость. Вы платите только за фактически затраченное время и материалы на проект.",
          },
          {
            title: "RETAINER",
            subtitle:
              "Вы оплачиваете фиксированную сумму ежемесячно, и мы гарантируем приоритетное обслуживание и гибкий доступ к нашим ресурсам.",
          },
        ],
      },
    ],
  },
  jobTypes: {
    title: "инструменты и\xa0Технологии",
    subtitle:
      "Обеспечиваем бесперебойную работу сайта, оперативное обновление контента и развитие функционала в кратчайшие сроки",
    items: [
      {
        title: "",
        caption: [
          "веб-приложения",
          "корпоративные сайты",
          "интернет-магазины",
          "ios",
          "android",
        ],
        items: [
          {
            type: "double-titled-list",
            title: "технологии",
            subtitle1: "Front-end",
            subtitle2: "Back-end",
            items: [
              "react js",
              "django",
              "next js",
              "python",
              "vue js",
              "node js",
              "nuxt js",
              "php",
              "html/js/css",
              "laravel",
            ],
          },
        ],
      },
      {
        title: "",
        caption: [
          "делаем",
          "+ расширение функционала сайта",
          "+ доработки по дизайну и UX/UI",
          "+ обновление контента",
          "+ лечение от вирусов",
          "+ увеличение скорости загрузки",
          "+ защиту от вирусов и хакерских атак",
          "+ мониторинг работоспособности сайта",
          "+ исправление ошибок и багов",
          "+ регулярное обновление системы и плагинов",
          "+ резервное копирование данных",
          "+ оптимизацию производительности",
        ],
        items: [
          {
            type: "double-titled-list",
            title: "cms",

            items: [
              "1с битрикс",
              "joomla",
              "modx",
              "tilda",
              "wordpress",
              "webflow",
              "opencart",
              "readymag",
              "preashop",
              "wix",
            ],
          },
          {
            type: "titled-list",
            title: "интеграции",

            items: [
              "bitrix 24",
              "amocrm",
              "1c-фитнес",
              "roistat",
              "comagic",
              "mango",
              "и др.",
            ],
          },
        ],
      },
    ],
  },
  seo: {
    title: "Техническая поддержка и сопровождение сайтов | SAGIROV",
    description:
        "Обеспечиваем бесперебойную работу вашего сайта: занимаемся обновлениями, мониторингом безопасности, устранением сбоев и настройкой серверов. Экспертный подход для надежной и быстрой работы веб-проектов.",
    keywords: [
      "техническая поддержка сайтов",
      "сопровождение сайта",
      "администрирование сайта",
      "поддержка веб-проектов",
      "обслуживание сайта",
      "мониторинг безопасности",
      "лечение и защита сайтов от вирусов",
      "доработка сайта",
    ],
    openGraph: {
      type: "article",
      title: "Техническая поддержка и сопровождение сайтов | SAGIROV",
      description:
          "Обеспечиваем бесперебойную работу вашего сайта: занимаемся обновлениями, мониторингом безопасности, устранением сбоев и настройкой серверов. Экспертный подход для надежной и быстрой работы веб-проектов.",
    },
  },
};

export default servicesItem;
