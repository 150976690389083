export interface Contact {
  name?: string;
  label: string;
  href?: string;
}

export const contact: { address: Contact; email: Contact; phone: Contact; telegram: Contact } = {
  address: {
    name: "Address",
    label:
      "Address office",
  },
  email: {
    name: "Email",
    label: "hello@sagirov.com",
    href: "mailto:hello@sagirov.com",
  },
  phone: {
    name: "Tel",
    label: "+7 (863) 270-33-45",
    href: "tel:+78632703345",
  },
  telegram: {
    name: "telegram",
    label: "sagirovcom",
    href: "https://telegram.me/sagirovcom",
  }
};

export const social: { vk: Contact; behance: Contact; dprofile: Contact; vc: Contact; dribbble:Contact } = {
  /*instagram: {
    label: "instagram",
    href: "https://www.instagram.com/sagirov_com/",
  }*/
  vk: {
    label: "vk",
    href: "https://vk.com/sagirov_com",
  },
  behance: {
    label: "behance",
    href: "https://www.behance.net/Sagirovcom",
  },
  dprofile: {
    label: "dprofile",
    href: "https://dprofile.ru/mikael",
  },
  vc: {
    label: "vc.ru",
    href: "https://vc.ru/u/491484-sagirov-com",
  },
  dribbble: {
    label: "dribbble",
    href: "https://dribbble.com/sagirov",
  }
};

export const contacts: Contact[] = [
  contact.address,
  contact.email,
  contact.phone,
  contact.telegram,
];

export const socials: Contact[] = [social.vk, social.behance, social.dprofile, social.vc];
export const socialsEn: Contact[] = [social.behance, social.dprofile, social.dribbble];