import Button from "components/common/Button";
import List from "components/common/List";
import Collapse from "components/common/Collapse";
import React, { useEffect, useMemo, useState } from "react";
import gsap from "gsap";

import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import classNames from "classnames";
import { VacancyData } from "data/types";
import Print from "components/common/Print";
import RevealTextByLine from "components/common/RevealInView/RevealTextByLine";
import SlideFadeOutWhenInView from "../ViewPortfolioItem/ViewPortfolioNovaGroup/SlideFadeOutWhenInView";

import { ScrollTrigger } from "gsap/ScrollTrigger";

import { vacancies } from "data";

interface VacancyItemProps {
  vacancy: VacancyData
}

interface VacancyItemRequirementProps {
  label: string;
  items: string[];
  className?: string;
  secondary?: boolean;
}

const VacancyItemRequirement = ({ label, items, secondary, className }: VacancyItemRequirementProps) => {
  return <SlideFadeOutWhenInView>
    {({ setTarget, setTriggerEl }) => (
      <div ref={setTriggerEl}>
        <p
          ref={setTarget()}
          className={classNames("vacancy-item__details-item-label", secondary ? "sublabel sublabel--secondary vacancy-item__details-item-label--secondary" : "label side-brackets",)}
        >
          <Print data={label} />
        </p>
        <List
          ref={setTarget({ selector: ".list__item" })}
          className={classNames("vacancy-item__details-item-list", secondary ? "sublabel sublabel--secondary vacancy-item__details-item-list--secondary" : "")}
          gap="lg"
          items={items}
          marked
        />
      </div>
    )}
  </SlideFadeOutWhenInView>
}

const VacancyItem = ({
  vacancy
}: VacancyItemProps) => {
  const [detailsOpened, setDetailsOpened] = useState(false);

  const handleMoreBtnClick = () => {
    setDetailsOpened(!detailsOpened);
  };

  useEffect(() => {
    ScrollTrigger.refresh();
  }, [detailsOpened]);

  return (
    <article className="vacancy-item">
      <div className="vacancy-item__container container">
        <header className="vacancy-item__header row">
          <div className="vacancy-item__col-with-title-and-skills col-11 col-md col-xl-5">
            <RevealWhenInView
              tag="h2"
              targetTag="span"
              className="heading vacancy-item__title"
              from={{ opacity: 0, y: 15 }}
              to={{ opacity: 1, y: 0 }}
            >
              <Print data={vacancy.name} />
            </RevealWhenInView>
            <RevealWhenInView
              from={{ opacity: 0, y: 15 }}
              to={{ opacity: 1, y: 0 }}
            >
              <List
                className="vacancy-item__skills"
                itemClassName="vacancy-item__skill sublabel sublabel--secondary"
                items={vacancy.skills}
              >
                {(skill) => <Print data={skill} />}
              </List>
            </RevealWhenInView>
          </div>
          <div className="vacancy-item__col-with-experience-and-link col-11 col-md-auto col-xl-6">
            <RevealWhenInView
              from={{ opacity: 0, y: 15 }}
              to={{ opacity: 1, y: 0 }}
              className="order-xl-1"
            >
              <p className="vacancy-item__experience label side-brackets order-xl-1">
                {vacancy.experienceLevels.join(", ")}
              </p>
            </RevealWhenInView>

            <RevealWhenInView
              from={{ opacity: 0, y: 15 }}
              to={{ opacity: 1, y: 0 }}
              className="order-xl-0"
            >
              <Button className="link link--normal label" onClick={
                () => gsap.to(window, { scrollTo: ".footer", duration: 1 })
              }>
                <Print data="view:vacancies.catalog.applyBtn" />
              </Button>
            </RevealWhenInView>
          </div>
        </header>
        <div className="vacancy-item__body row">
          <div className="vacancy-item__col-with-description col-11 col-md order-xl-1">
            <Print data={vacancy.description} >
              {(data) => (
                <RevealTextByLine className="vacancy-item__description" wordClassName={(l, w) => l === 0 && w === 0 && "offset-md-4 offset-xl-3"}>
                  {data}
                </RevealTextByLine>
              )}
            </Print>
          </div>
          <div className="vacancy-item__col-with-more-btn col-11 col-md order-xl-0 col-xl-5">
            <Button
              onClick={handleMoreBtnClick}
              className={classNames("button footnote vacancy-item__more-btn", { "is-active": detailsOpened })}
              renderRight={() => (
                <RevealWhenInView from={{ opacity: 0, y: -25 }}>
                  <div
                    className={classNames(
                      "sprite-icon sprite-icon--arrow-down",
                      {
                        "is-active": detailsOpened,
                      }
                    )}
                  />
                </RevealWhenInView>
              )}
            >
              <RevealWhenInView>
                <Print data="view:vacancies.catalog.moreBtn" />
              </RevealWhenInView>
            </Button>
          </div>
          <div className="vacancy-item__col-with-details col-11 order-3">
            <Collapse className="vacancy-item__details" open={detailsOpened}>
              <div className="vacancy-item__details-container">
                <div className="vacancy-item__details-inner row gaps--horizontal-extra-large">
                  <div className="vacancy-item__details-item vacancy-item__details-item--header col-11 col-md-5">
                    <RevealWhenInView delay={0}>
                      <h3 className="vacancy-item__details-title heading heading--indent-fix-off">
                        <Print data="Requirements" />
                      </h3>
                    </RevealWhenInView>
                  </div>

                  <div className="vacancy-item__details-item col-11 col-md-6 order-md-1">
                    <VacancyItemRequirement
                      label="view:vacancies.catalog.requirements-labels.challenges"
                      items={vacancy.requirements.challenges}
                    />
                  </div>

                  <div className="vacancy-item__details-item col-11 col-md-6 order-md-3">
                    <VacancyItemRequirement
                      label="view:vacancies.catalog.requirements-labels.skills"
                      items={vacancy.requirements.skills}
                    />
                  </div>


                  <div className="vacancy-item__details-item vacancy-item__details-item--secondary vacancy-item__details-item--align-items col-11 col-md-5 order-md-2">
                    <VacancyItemRequirement
                      secondary
                      label="view:vacancies.catalog.requirements-labels.extra-skills"
                      items={vacancy.requirements.extraSkills}
                    />
                  </div>

                  <div className="vacancy-item__details-item col-11 col-md-6 offset-md-5 order-md-4">
                    <VacancyItemRequirement
                      label="view:vacancies.catalog.requirements-labels.working-conditions"
                      items={vacancy.requirements.workingConditions || vacancies.defaults.requirements.workingConditions}
                    />

                    <RevealWhenInView delay={0} className="vacancy-item__details-item-sublabel sublabel sublabel--secondary order-md-5">
                      <Print data={vacancy.requirements.annotation || vacancies.defaults.requirements.annotation} />
                    </RevealWhenInView>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </article>
  );
};

export default VacancyItem;
