import ServicesArticles from "components/common/services/ServicesArticles/ServicesArticles";

function ViewServicestechnicalSupportItem() {
  return (
    <ServicesArticles
      sectionData={{
        title: "Статьи",
        headText:
          "Рассказываем об интересных кейсах из нашей практики, делимся инсайтами и показываем, как творческий подход помогает решать сложные задачи и создавать уникальные проекты",
        items: [{
          title: "Как удвоить прибыль логистической компании с помощью UX: раскрываем все секреты",
          date: "20 мая 2024",
          time: "vc.ru, 17 минут",
          imagePath: "/images/services/articles/aist.png",
          link: "vc.ru",
          href: "https://vc.ru/design/1172351-kak-udvoit-pribyl-logisticheskoi-kompanii-s-pomoshyu-ux-raskryvaem-vse-sekrety"
        },
        {
          title: "Как мы излечили сайт крупного застройщика от вирусов и обновили ядро сайта до последней версии вручную",
          date: "30 ноября 2023",
          time: "vc.ru, 29 минут",
          imagePath: "/images/services/articles/sk10.png",
          link: "vc.ru",
          href: "https://vc.ru/dev/419580-kak-ostanovit-sliv-5-mln-mes-ili-bezopasnost-saita-dlya-krupnogo-zastroishika"
        }],
      }}
    />
  );
}

export default ViewServicestechnicalSupportItem;
