import ViewServicesBrandingItem from "components/views/ViewServicesItem/ViewServicesBrandingItem";
import { ServicesItemData } from "./types";

// @ts-ignore
const servicesItem: ServicesItemData = {
  id: "ux-ui",
  component: ViewServicesBrandingItem,
  title: "ux-ui",
  name: "Ux / UI",

  color: "green",
  leadSection: {
    title: "Ux/ui-Дизайн",
    description:
        "дизайн, который решает задачи бизнеса и завоевывает сердца пользователей",
    subdescription: "",
    ourClients: ["sibur", "gazprom", "rosatom", "sberbank", "sk10", "89+"],
    works: [
      {
        title: "делаем",
        items: ["Веб-сайты", "Приложения", "Интерфейсы"],
      },
      {
        title: "",
        items: [
          "анализ и аудит",
          "Ux-проектирование",
          "визуальный дизайн",
          "тестирование и улучшение",
        ],
      },
    ],
  },
  sliderSection: [
    {
      title: "SK10",
      subtitle: "Разработка и поддержка проекта с 2019 года по Н.В.",
      headerItems: [
        "Тех поддержка",
        "UI/UX дизайн",
        "Разработка сайта",
        "Разработка функционала",
      ],
      body: {
        title: "Задача",
        items: [
          "проектирование и разработка сайта",
          "техническая поддержка сайта",
          "Дизайн и разработка новых разделов",
        ],
      },
      logo: "/images/developer-sites/sk10/logo",
      background: "/images/developer-sites/sk10/background",
      nextSlideButton: <>Следующий проект</>,
    },

    {
      title: "Медный голем",
      subtitle: "UX/UI-дизайн и разработка сайта",
      headerItems: [
        "Разработка сайта",
        "UX/UI дизайн",
        "разработка функционала",
      ],
      body: {
        title: "Задача",
        items: [
          "проектирование и дизайн сайта",
          "верстка и разработка функционала",
        ],
      },
      logo: "/images/developer-sites/cuprum-golem/logo",
      background: "/images/developer-sites/cuprum-golem/background",
      nextSlideButton: (
          <>
            Следующий <br /> проект{" "}
          </>
      ),
    },
    {
      title: "CLT",
      subtitle: "UX/UI-дизайн и разработка сайта",
      headerItems: [
        "разработка сайта",
        "UI/UX дизайн",
        "Разработка функционала",
      ],
      body: {
        title: "Задача",
        items: [
          "проектирование и дизайн сайта",
          "верстка и разработка функционала",
        ],
      },
      logo: "/images/developer-sites/clt/logo",
      background: "/images/developer-sites/clt/background",
      nextSlideButton: (
          <>
            Следующий <br /> проект{" "}
          </>
      ),
    },
    {
      title: "CFPS",
      subtitle: "Разработка мобильного приложения для контроля финансов",
      headerItems: [
        "разработка сайта",
        "UI/UX дизайн",
        "Разработка функционала",
      ],
      body: {
        title: "Задача",
        items: [
          "проектирование и дизайн сайта",
          "верстка и разработка функционала",
        ],
      },
      logo: "/images/developer-sites/cfps/logo",
      background: "/images/developer-sites/cfps/background",
      nextSlideButton: (
          <>
            Следующий <br /> проект{" "}
          </>
      ),
    },
    {
      title: "Дома века",
      subtitle: "Разработка и поддержка проекта с 2018 года по Н.В.",
      headerItems: [
        "разработка сайта",
        "UI/UX дизайн",
        "Тех поддержка",
        "Разработка функционала",
      ],
      body: {
        title: "Задача",
        items: [
          "проектирование и разработка сайта",
          "техническая поддержка сайта",
          "Дизайн и разработка новых разделов",
        ],
      },
      logo: "/images/developer-sites/doma-veka/logo",
      background: "/images/developer-sites/doma-veka/background",
      nextSlideButton: (
          <>
            Следующий <br /> проект{" "}
          </>
      ),
    },
  ],

  faqsSection: {
    title: "Faqs",
    items: [
      {
        title: "Что нужно предоставить для начала работы?",
        text: "Нам понадобится информация о вашем бизнесе, целевой аудитории, задачах проекта и примеры дизайнов, которые вам нравятся. Также важно предоставить технические требования и существующие материалы",
      },
      {
        title: "Как мы будем взаимодействовать в процессе работы?",
        text: "Мы будем регулярно общаться, проводить встречи, показывать промежуточные результаты и обсуждать их с вами. Все изменения согласовываются вместе с вами.",
      },
      {
        title: "Как проходит процесс дизайна?",
        text: "Мы начинаем с исследования и анализа, затем переходим к созданию сценариев и прототипов, разрабатываем визуальный дизайн, проводим тестирования с пользователями, вносим правки и передаем готовый дизайн разработчикам.",
      },
      {
        title: "Поддерживаете ли вы проект после завершения работы?",
        text: "Да, мы готовы консультировать и помогать с реализацией дизайна разработчиками, а также выполнять дополнительные доработки по вашему запросу.",
      },
      {
        title: "Можете ли вы работать с нашими разработчиками?",
        text: "Да, мы тесно сотрудничаем с вашими разработчиками, предоставляем все необходимые материалы и консультируем по вопросам внедрения дизайна, чтобы обеспечить его правильную интеграцию.",
      },
    ],
  },

  workStagesSection: {
    title: "ЭТАПЫ РАБОТЫ",
    description:
        "Отлаженный процесс работы команды для комфортного сотрудничества и безупречного результата",
    items: [
      {
        tickerText: "ПЕРВЫЙ ЭТАП",
        subtitle: "исследование и проектирование",
        max_step_number: 8,
        items: [
          {
            title: "Брифинг",
            subtitle: "",
            step_number: 1,
            text: "Уточняем требования и цели, определяем состав и приоритеты работ, планируем этапность работ. Отталкиваемся от задачи и ресурсов",
          },
          {
            title: "Исследование",
            subtitle: "",
            step_number: 2,
            text: "Проводим UX-аудит текущего сайта и сайтов конкурентов, выявляем проблемы, анализируем лучшие практики",
          },
          {
            title: "Прототипирование",
            subtitle: "",
            step_number: 3,
            text: "Разрабатываем детализированные прототипы страниц, готовим требования к функционалу и контенту",
          },
        ],
      },
      {
        tickerText: "второй этап",
        subtitle: "Работа над визуалом",
        max_step_number: 8,
        items: [
          {
            title: "Референсы",
            subtitle: "",
            step_number: 4,
            text: "Через референсы для проекта мы определяем общее направление стилистики, фиксируем ключевые идеи. Убеждаемся, что верно уловили идею и думаем в одном направлении с вами",
          },
          {
            title: "Концепция",
            subtitle: "разработка концепции дизайна",
            step_number: 5,
            text: "на основе согласованных референсов разрабатываем варианты визуальной концепции сайта, приложения или сервиса",
          },
          {
            title: "Дизайн",
            subtitle: "всех страниц, экранов и адаптивов",
            step_number: 6,
            text: "после согласования визуальной концепции переходим к разработке детализированных макетов всех страниц и адаптивов вашего проекта",
          },
        ],
      },
      {
        tickerText: "третий этап",
        subtitle: "Разработка и пост-продакшн",
        max_step_number: 8,
        items: [
          {
            title: "Передача разработке",
            subtitle: "Подготовка макетов к верстке",
            step_number: 7,
            text: "чтобы разработчики могли быстро и эффективно перенести дизайн в код, мы формируем стили для цветов/шрифтов в файле Figma, создаем компоненты для всех элементов и их состояний, после чего можем передать макеты программистам",
          },
          {
            title: "Сопровождение",
            subtitle: "",
            step_number: 8,
            text: "Взаимодействие с разработчиками. Контроль соответствия дизайна при реализации. Проведение ревью готового продукта",
          },
        ],
      },
    ],
  },
  customerRewiewsSection: {
    title: "отзывы клиентов",
    subtitle:
        "Мы ответственны в своём деле и всегда нацелены на достижение лучшего результата",
    reviews: [
      {
        title: "Мартынович Михаил",
        subtitle: "руководитель ИТ департамента - застройщик ооо СК10",
        point: 5,
        text: "Команда студии SAGIROV.com показала себя надежным подрядчиком, ответственно, оперативно и квалифицированно выполняющим работы по проектированию, сопровождению и обслуживанию сайта. Мы очень довольны результатами проделанной работы и надеемся на дальнейшее совместное сотрудничество.",
        icon: "sk10-paginator.svg",
      },

      {
        title: "Георги Атоян",
        subtitle: "Генеральный директор Дома-Века",
        point: 5,
        text: "Нам очень нравится результат, который мы получили. Мы давно хотели для себя сайт, который не будет похож на другие, типовые сайты из нашей отрасли. Нашим клиентам он тоже очень нравится. Самое главное, что он получился информативным, современным и понятным. Многие наши конкуренты тоже переделали свои сайты и позаимствовали у нас много идей.",
        icon: "doma-veka-paginator.svg",
      },
      {
        title: "Ильин Игорь",
        subtitle: "Учередитель ООО Новация",
        point: 5,
        text: "Выражаем благодарность Студии Микаэла Сагирова за разработку сайт для нашей компании - быстро и качественно, в процессе работы оперативно вносили правки. Подсказывали как будет лучше и какие вещи нам не подходят на основе аналитики и опыта. По итогу работы разработали дизайн, сайт, выполнили ряд интеграций. Работой довольны, готовы рекомендовать Студию как надежного подрядчика.",
        icon: "nova-group-paginator.svg",
      },
      {
        title: "Екатерина Цыганкова",
        subtitle: "Маркетолог Корпорации СКЭСС",
        point: 5,
        text: "На протяжении сотрудничества команда Студии SAGIROV.co демонстрирует высокий профессионализм, гибкость и быстрое реагирование в решении поставленных задач по поддержке и техническому обслуживанию сайта, индивидуальный подход в решении поставленных задач, а также их своевременное выполнение. Нам удалось построить эффективную коммуникацию, дружеские и доверительные отношения.",
        icon: "skess-paginator.svg",
      },
    ],

    footerText: (
        <>
          следующий <br /> отзыв
        </>
    ),
  },

  priceSection: {
    title: "Цены",
    subtitle:
        "Ценообразование всегда максимально прозрачно и рассчитывается по простой формуле",
    dopclasses: "",
    items: [
      {
        type: "price",
        title: "Дизайн сайта",
        caption:
            "Рассчитывается на основе трудозатрат на отдельный вид работ и зависит от количества кругов правок, концепций, объема работ и профиля привлекаемых специалистов",
        items: [
          {
            title1: "Лендинг / Промо",
            caption1: "",
            title2: "от\u00a0200\u00a0000\u00a0р.",
            caption2: "от 15 до 20 дней",
            isCross: false,
          },
          {
            title1: "Интернет-магазин",
            caption1: "",
            title2: "от\u00a0500\u00a0000\u00a0р.",
            caption2: "от 45 дней",
            isCross: false,
          },
          {
            title1: "Многостраничный сайт",
            caption1: "",
            title2: "от\u00a0700\u00a0000\u00a0р.",
            caption2: "от 60 дней",
            isCross: false,
          },
        ],
      },
      {
        type: "price",
        title: "Дизайн приложения",
        caption:
            "Рассчитывается на основе трудозатрат на отдельный вид работ и зависит от количества кругов правок, концепций, объема работ и профиля привлекаемых специалистов",
        items: [
          {
            title1: "iOS / Android",
            caption1: "",
            title2: "от\u00a0300\u00a0000\u00a0р.",
            caption2: "от 15 до 20 дней",
            isCross: false,
          },
        ],
      },
      {
        type: "price",
        title: "Продуктовый дизайн",
        caption: "разработка дизайна интерфейсов сервисов и интернет порталов, где работа над задачами идет итерациями",
        isCross: true,
        items: [
          {
            title1: "Кол-во часов",
            caption1:
                "это затраченное время на решение задач в рамках договора",
            title2: "1800₽/час",
            caption2: "от 50 часов в месяц",
            isCross: true,
          },
        ],
      },

      {
        type: "formats",
        title: "Форматы оплаты",
        caption: "",
        aunchor: {
          to: ".footer",
          // @ts-ignore
          text: (
              <>
                заказать расчет <br /> стоимости проекта
              </>
          ),
        },
        items: [
          {
            title: "fix",
            subtitle:
                "В рамках этого формата работы мы предлагаем фиксированную стоимость за определённый проект или задачу.",
          },
          {
            title: "Time material",
            subtitle:
                "Этот формат предоставляет максимальную гибкость. Вы платите только за фактически затраченное время и материалы на проект.",
          },
          {
            title: "RETAINER",
            subtitle:
                "Вы оплачиваете фиксированную сумму ежемесячно, и мы гарантируем приоритетное обслуживание и гибкий доступ к нашим ресурсам.",
          },
        ],
      },
    ],
  },

  jobTypes: {
    title: "Услуги",
    subtitle:
        "ux/ui дизайн с фокусом на удобные и функциональные решения для бизнеса и влюбляет в себя пользователей",
    items: [
      {
        title: "",
        caption: [
          "веб-приложения",
          "корпоративные сайты",
          "лендинги / промо",
          "интернет-магазины",
          "ios",
          "android",
        ],
        items: [
          {
            type: "titled-list",
            title: "ИССЛЕДОВАНИЯ",

            items: [
              "сбор информации от клиента",
              "аналитика: изучение конкурентов, определение и анализ целевой аудитории",
              "формирование структуры сайта или мобильного приложения",
            ],
          },
          {
            type: "titled-list",
            title: "UX дизайн",

            items: [
              "создание карты сайта и структуры контента",
              "разработка от низкоуровневых до интерактивных прототипов",
              "проведение пользовательских исследований и тестирования",
            ],
          },
        ],
      },
      {
        title: "",
        caption: [
          "а также",
          "+ проведение юзабилити-тестирования",
          "+ внесение изменений на основе обратной связи",
          "+ создание дизайн-гайдлайнов",
          "+ авторский надзор",
        ],
        items: [
          {
            type: "titled-list",
            title: "UI дизайн",

            items: [
              "разработка макетов экранов",
              "создание иконок и графических элементов",
              "подбор и применение цветовых схем и типографики",
              "создание дизайн-систем и библиотек компонентов",
              "адаптивный дизайн для различных устройств",
            ],
          },
        ],
      },
    ],
  },
  ourExpireanceSection: {
    title: (
        <span className={"heading-2"}>
        В каких случаях компании необходим UX/UI-дизайн?
      </span>
    ),
    headText: "Для чего нужна услуга",
    description:
        "мы поможем вам улучшить пользовательский опыт и повысить удовлетворенность и лояльность клиентов",
    items: [
      {
        title: "01",
        description:
            "Разработка нового или улучшение существующего сайта / приложения",
      },
      {
        title: "02",
        description:
            "Улучшение пользовательского опыта и увеличение удовлетворенности клиентов",
      },
      {
        title: "03",
        description:
            "Повышение конверсии и улучшение показателей взаимодействия на сайте или в приложении",
      },
      {
        title: "04",
        description:
            "Оптимизация навигации и облегчение доступа к ключевой информации",
      },
      {
        title: "05",
        description:
            "Соответствие современным тенденциям дизайна и конкурентоспособности на рынке",
      },
    ],
  },
  seo: {
    title: "UI/UX дизайн и прототипирование | SAGIROV",
    description:
        "Проектируем удобные интерфейсы и создаем визуальные концепты для веб-сайтов и мобильных приложений. Улучшаем пользовательский опыт и повышаем конверсию продукта.",
    keywords: [
      "ui ux дизайн",
      "дизайн интерфейсов",
      "ux исследование",
      "прототипирование",
      "дизайн мобильных приложений",
      "дизайн сайтов",
      "редизайн сайта",
      "продуктовый дизайн",
    ],
    openGraph: {
      type: "article",
      title: "UI/UX дизайн и прототипирование | SAGIROV",
      description:
          "Проектируем удобные интерфейсы и создаем визуальные концепты для веб-сайтов и мобильных приложений. Улучшаем пользовательский опыт и повышаем конверсию продукта.",
    },
  },
};

export default servicesItem;
