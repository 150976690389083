import { useRef } from "react";
import { StageItems } from "./StageItems";
import s from "./index.module.scss";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";
import { useViewServicesItemContext } from "components/views/ViewServicesItem/ViewServicesItem";
interface ViewDeveloperSitesStagesSectionProps {}

const ServicesStagesSection = (props: ViewDeveloperSitesStagesSectionProps) => {
  const triggerRef = useRef<HTMLDivElement>(null);
  const { data } = useViewServicesItemContext();
  return (
    <DynamicBackgroundTrigger>
      <section
        ref={triggerRef}
        className={
          `section text--light section--with-scroll-appear-background background--${data.color} ` +
          s["view-developer-sites-stages-section"]
        }
      >
        <div className="section-container container">
          <div className="view-developer-sites-stages-section__inner">
            <div
              className={
                "row " + s["view-developer-sites-stages-section__header"]
              }
            >
              <div className="col-11 col-xl-7 heading">
                <RevealWhenInView>
                  <h2 className="heading service-heading2">
                    {data.workStagesSection.title}
                  </h2>
                </RevealWhenInView>
              </div>
              <div className="col-md-7 offset-md-4 offset-xl-0 col-xl-4">
                <RevealWhenInView>
                  <p className="text--indent-md-quarter">
                    {data.workStagesSection.description}
                  </p>
                </RevealWhenInView>
              </div>
            </div>
          </div>
        </div>
        {/* @ts-ignore */}
        {data.workStagesSection.items.map((item) => {
          return <StageItems {...item} />;
        })}
      </section>
    </DynamicBackgroundTrigger>
  );
};

export default ServicesStagesSection;
