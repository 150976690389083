import Section from "components/layouts/Section";

import s from "./index.module.scss";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";
import RevealTextByLine from "components/common/RevealInView/RevealTextByLine";
import Icon from "components/common/Icon";

function ServicesArticles({
  sectionData,
}: {
  sectionData: {
    headText: string;
    title: string;
    items: {
      title: string;
      link: string;
      href: string;
      imagePath: string;
      date: string;
      time: string;
    }[];
  };
}) {
  return (
    <DynamicBackgroundTrigger>
      <Section
        className={s["services-articles-section"] + ` background--white `}
      >
        <div className={" section-container container"}>
          <div>
            <div className={"row " + s["services-articles-section__header"]}>
              <RevealWhenInView className="col-lg-5">
                <h2 className="heading">{sectionData.title}</h2>
              </RevealWhenInView>
              <div className="col-lg-5 col-md-7 offset-auto">
                <RevealTextByLine
                  wordClassName={(l, w) => l === 0 && w === 0 && "offset-md-3"}
                >
                  {sectionData.headText}
                </RevealTextByLine>
              </div>
            </div>
          </div>
          <div className={s["services-articles-section__body"] + " row"}>
            {sectionData.items.map((item) => {
              return (
                <div className={s.item}
                     onClick={() => {
                        if (item.href) {
                          window.open(item.href, "_blank"); // Открываем ссылку в новой вкладке
                        }
                      }}>
                  <img src={item.imagePath} />
                  <div className={s.content + " row"}>
                    <div className={s.left + " row col-lg-8 order-lg-1 order-2"}>
                      <span className=" col-lg-9 side-brackets ">
                        {item.title}
                      </span>
                      <span className=" caption-black col-lg-11 col-9">{item.time}</span>
                      <div className={"offset-auto col-auto d-lg-none d-flex"}>
                        <Icon
                            name="arrow-down"
                            className={"link_arrow " + s["arrow-link"]}
                        />
                      </div>
                    </div>
                    <div className={s.right + " col-lg-3 order-lg-2 order-1 row"}>
                      <span className=" offset-auto col-11 caption ">
                        {item.date}
                      </span>
                      <div className={"offset-auto col-11 d-lg-flex d-none"}>
                        <Icon
                          name="arrow-down"
                          className={"link_arrow " + s["arrow-link"]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Section>
    </DynamicBackgroundTrigger>
  );
}

export default ServicesArticles;
