import ServicesItemOurExperienceSection from "components/common/services/OurExperience/OurExpierenceSection";

function ViewServicesDesignSupportItem() {
  return (
    <ServicesItemOurExperienceSection
      sectionData={{
        title: <span>В каких случаях компании нужна дизайн-поддержка?</span>,
        headText: "варианты запроса",
        description:
          "мы поможем вам улучшить визуальную коммуникацию, запустить новый проект или повысить конкурентоспособность на рынке",
        items: [
          {
            title: "01",
            description: "Перегрузка в собственном дизайн-отделе",
          },
          {
            title: "02",
            description:
              "Развитие дизайн-системы",
          },
          {
            title: "03",
            description:
              "Необходимы дизайнеры с разными специализациями",
          },
          {
            title: "04",
            description:
              "Недостаток компетенций для решения концептуальных задач",
          },
          {
            title: "05",
            description:
              "Недостаток компетенций для решения концептуальных задач",
          },
          {
            title: "06",
            description:
              "Важен свежий взгляд профессионалов",
          },
        ],
      }}
    />
  );
}

export default ViewServicesDesignSupportItem;
