import ViewPortfolioWomanSportSite from "components/views/ViewPortfolioItem/ViewPortfolioWomanSportSite";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";

const portfolioItem: PortfolioItemDataWithComponent = {
  id: "woman-sport-site",
  component: ViewPortfolioWomanSportSite,
  title: "woman-sport",
  name: "Woman sport",
  altName: "Women's\nfitness club",
  year: 2019,
  link: {
    href: "https://www.behance.net/gallery/76903475/Woman-Sport",
    label: "wsportclub.ru",
  },

  socials: [
    {
      label: "behance",
      href: "https://www.behance.net/gallery/76903475/Woman-Sport",
    },
  ],

  preview: {
    label: "training portal",
    media: {
      type: "video",
      src: "https://vimeo.com/542713244",
    },
  },

  poolOfWorks: [
    "UX|UI design",
    "Branding",
    "Page making",
    "Personal accounts",
    // "Integration with 1c-bitrix",
    "Integration",
    "1c-fitness",
  ],

  type: "site",
  subtype: "site",

  description:
    "women's fitness club with unique circuit training. For him, we have created a bright multi-page website with a personal account.",
  shortDescription:
    "website for women's fitness club",


  relatedId: "woman-sport-branding",
  seo: {
    title: "Website development for the Woman Sport fitness club with a personal account",
    description: "Design and creation of a website for the Woman Sport fitness club, including integration with 1C fitness, a system of personal accounts for clients and trainers, as well as a mobile and tablet version of the site",
    keywords: ["creation of a website for a fitness club",
      "development of a website for a sports club",
      "fitness club website design",
      "personal account on the site of the fitness club",
      "website integration with 1c fitness"],
    openGraph: {
      type: "article",
      title: "Website development for the Woman Sport fitness club with a personal account",
      description: "Design and creation of a website for the Woman Sport fitness club, including integration with 1C fitness, a system of personal accounts for clients and trainers, as well as a mobile and tablet version of the site",
    }
  },

  pageContent: {
    sections: {
      mobileVersion: {
        theme: {
          backgroundColor: "#E10066",
          color: "#fff",
        },
        frameCount: 160
      }
    }
  }
};

export default portfolioItem;
