import { memo, useRef } from "react";

import Footer from "components/layouts/Footer";
import View from "components/layouts/View";
import ServicesItemLeadSection from "../../common/services/LeadSection/ServicesItemLeadSection";
import JobPrice from "../../common/services/JobPrice/JobPrice";
import JobTypes from "../../common/services/JobTypes/JobTypes";

import "./index.scss";
import ViewDeveloperSitesPhotoSection from "./ViewDeveloperSitePhotoSection/ViewDeveloperSitesPhotoSectionPhotoSection";
import ServicesFaqsSection from "../../common/services/FaqsSection/FaqsSection";
import ViewDeveloperSitesOurExperienceSection from "./ViewDeveloperSitesOurExpireance/ViewDeveloperSitesOurExpireanceSection";
import ProjectsSwiperSection from "../../common/services/ProjectsSwiper/ProjectsSwiperSection";
import CustomerReviewsSection from "../../common/services/CustomerReviews/CustomerReviewsSection";

interface ViewDeveloperSitesProps {}

const ViewDeveloperSites = (props: ViewDeveloperSitesProps) => {
  const viewElRef = useRef<HTMLDivElement>(null);

  return (
    <View ref={viewElRef} className="view-developer-sites">
      <ServicesItemLeadSection />
      <ViewDeveloperSitesPhotoSection
        path="views/developer-sites"
        imageSrc="town-homes.jpeg"
      />
      <ViewDeveloperSitesOurExperienceSection />
      <ProjectsSwiperSection />
      <CustomerReviewsSection />
      <ViewDeveloperSitesPhotoSection
        path="views/developer-sites"
        imageSrc="beautiful-home-1.jpeg"
      />
      <JobTypes />
      <JobPrice />
      <ServicesFaqsSection />
      <Footer className="background--gray" />
    </View>
  );
};

export default memo(ViewDeveloperSites);
