import { ServicesItemData } from "./types";
import ViewServicesDesignSupportItem from "components/views/ViewServicesItem/ViewServicesDesignSupportItem";

const servicesItem: ServicesItemData = {
  id: "design-support",
  component: <ViewServicesDesignSupportItem />,
  componentPlace: 2,
  title: "design-support",
  name: "Design Support",

  color: "purple",
  ourExpireanceSection: {
    title: (
      <span className="heading-2">
        <i className="heading italic-text">надежно и удобно&#160;—</i> опытные
        дизайнеры под началом арт-директора всегда готовы помочь с любой задачей
      </span>
    ),
    headText: "Преимущества",
    description: "aunchor",
    items: [
      {
        title: "Максимальная прозрачность",
        description: "Все оценивается в часах с фиксацией дат реализации",
      },
      {
        title: "гарантия результата",
        description:
          "Работаем над задачами до достижения необходимого результата",
      },
      {
        title: "отслеживание прогресса",
        description:
          "Используем Figma — вы всегда можете отслеживать текущий прогресс онлайн",
      },
      {
        title: "Ответственный подход",
        description:
          "Всегда соблюдаем договоренности. Наши обязательства прописаны в договоре",
      },
      {
        title: "Комфортное планирование",
        description:
          "Планируем работы с комфортными для вас интервалами контрольных точек",
      },
      {
        title: "Оперативная коммуникация",
        description:
          "В постоянной коммуникации с вашей командой в чатах и на созвонах ",
      },
    ],
  },
  leadSection: {
    title: "Дизайн-\nподдержка",
    description: "Усиливаем или полностью заменяем ваш дизайн-отдел",
    subdescription: "",
    ourClients: ["sibur", "gazprom", "rosatom", "sberbank", "sk10", "89+"],
    works: [
      {
        title: "делаем",
        items: [
          "Встраиваемся в ваши бизнес-процессы",
          "Разгружаем отдел дизайна",
          "сдаем качественную работу в срок",
        ],
      },
    ],
  },
  sliderSection: [
    {
      title: "AAA",
      subtitle: "Поддержка проекта с 24 года по Н.В.",
      headerItems: ["Тех поддержка"],
      body: {
        title: "Задача",
        items: ["Дизайн баннеров для социальных сетей"],
      },
      logo: "/images/developer-sites/aaa/logo",
      background: "/images/developer-sites/aaa/background",
      nextSlideButton: <>Следующий проект</>,
    },
    {
      title: "Alfaleads",
      subtitle: "Поддержка проекта с 24 года по Н.В.",
      headerItems: ["Тех поддержка"],
      body: {
        title: "Задача",
        items: ["Дизайн баннеров для социальных сетей"],
      },
      logo: "/images/developer-sites/alfaleads/logo",
      background: "/images/developer-sites/alfaleads/background",
      nextSlideButton: (
        <>
          Следующий <br /> проект{" "}
        </>
      ),
    },
    {
      title: "СК10",
      subtitle: "Поддержка проекта с 2019 года по Н.В.",
      headerItems: [
        "Тех поддержка",
        "UI/UX дизайн",
        "Разработка сайта",
        "Разработка функционала",
      ],
      body: {
        title: "Задача",
        items: [
          "проектирование и разработка сайта",
          "техническая поддержка сайт",
          "Дизайн и разработка новых разделов",
        ],
      },
      logo: "/images/developer-sites/sk10/logo",
      background: "/images/developer-sites/sk10/background",
      nextSlideButton: (
        <>
          Следующий <br /> проект{" "}
        </>
      ),
    },
  ],

  faqsSection: {
    title: "Faqs",
    items: [
      {
        title: "Практикуете ли вы постоплату?",
        text: "Да. О сроках договариваемся индивидуально.",
      },
      {
        title: "можем ли мы начать работу без договора?",
        text: "Оформление договора часто затягивается, в таких случаях мы приступаем к работе по гарантийному письму.",
      },
      {
        title: "Какие задачи вы решаете в рамках дизайн-поддержки?",
        text: "Мы занимаемся обновлением графики, созданием новых элементов, оптимизацией существующего дизайна, улучшением UX/UI, разработкой маркетинговых материалов и поддержкой визуального стиля вашего проекта.",
      },
      {
        title: "Сколько времени занимает выполнение одного запроса?",
        text: "Сроки зависят от сложности задачи. Мелкие изменения могут быть выполнены в течение нескольких часов, а более сложные задачи могут занять от одного рабочего дня/недели или больше.",
      },
      {
        title: "Какие данные нужно предоставить для начала работы?",
        text: "Нам потребуется доступ к текущим дизайнам, информация о ваших предпочтениях и требованиях, а также любые исходные материалы, которые могут быть полезны для работы.",
      },
    ],
  },

  workStagesSection: {
    title: "ЭТАПЫ РАБОТЫ",
    description:
      "Настраиваем рабочий процесс для комфортного взаимодействия с командой поддержки",
    items: [
      {
        tickerText: "ПЕРВЫЙ ЭТАП",
        subtitle: "включаемся в работу",
        max_step_number: 6,
        items: [
          {
            title: "Брифинг",
            subtitle: "",
            step_number: 1,
            text: "начальный этап, на котором мы встречаемся с вами, чтобы понять ваши потребности и ожидания от поддержки. Мы обсуждаем основные задачи и цели проекта",
          },
          {
            title: "Подключение",
            subtitle: "к таск-мессенджеру",
            step_number: 2,
            text: "Для удобства и прозрачности сотрудничества мы предоставляем доступ к таск-трекерам, где вы можете отслеживать весь процесс работы или мы интегрируемся в ваши корпоративные системы управления задачами и проектами",
          },
          {
            title: "Перенос",
            subtitle: "",
            step_number: 3,
            text: "Запрашиваем все необходимые данные от предыдущего подрядчика: доступы, документацию и инструкции",
          },
          {
            title: "Ознакомление",
            subtitle: "c проектом",
            step_number: 4,
            text: "изучаем материалы и ресурсы, настраиваем рабочие среды, проводим аудит текущего дизайна",
          },
        ],
      },
      {
        tickerText: "второй этап",
        subtitle: "работа над проектом",
        max_step_number: 6,
        items: [
          {
            title: "Обсуждение",
            subtitle: "процесса",
            step_number: 5,
            text: "Мы обсуждаем с вами план действий, задачи, которые необходимо решить быстро сейчас и согласования долгосрочного плана работ по развитию всего проекта",
          },
          {
            title: "Работа",
            subtitle: "по спринтам и дедлайнам",
            step_number: 6,
            text: "Мы разбиваем задачи на спринты с чёткими сроками выполнения, что позволяет управлять временем и ресурсами более эффективно",
          },
        ],
      },
    ],
  },
  customerRewiewsSection: {
    title: "отзывы клиентов",
    subtitle:
      "Мы ответственны в своём деле и всегда нацелены на достижение лучшего результата",
    reviews: [
      {
        title: "Мартынович Михаил",
        subtitle: "руководитель ИТ департамента - застройщик ооо СК10",
        point: 5,
        text: "Команда студии SAGIROV.com показала себя надежным подрядчиком, ответственно, оперативно и квалифицированно выполняющим работы по проектированию, сопровождению и обслуживанию сайта. Мы очень довольны результатами проделанной работы и надеемся на дальнейшее совместное сотрудничество.",
        icon: "sk10-paginator.svg",
      },

      {
        title: "Георги Атоян",
        subtitle: "Генеральный директор Дома-Века",
        point: 5,
        text: "Нам очень нравится результат, который мы получили. Мы давно хотели для себя сайт, который не будет похож на другие, типовые сайты из нашей отрасли. Нашим клиентам он тоже очень нравится. Самое главное, что он получился информативным, современным и понятным. Многие наши конкуренты тоже переделали свои сайты и позаимствовали у нас много идей.",
        icon: "doma-veka-paginator.svg",
      },
      {
        title: "Ильин Игорь",
        subtitle: "Учередитель ООО Новация",
        point: 5,
        text: "Выражаем благодарность Студии Микаэла Сагирова за разработку сайт для нашей компании - быстро и качественно, в процессе работы оперативно вносили правки. Подсказывали как будет лучше и какие вещи нам не подходят на основе аналитики и опыта. По итогу работы разработали дизайн, сайт, выполнили ряд интеграций. Работой довольны, готовы рекомендовать Студию как надежного подрядчика.",
        icon: "nova-group-paginator.svg",
      },
      {
        title: "Екатерина Цыганкова",
        subtitle: "Маркетолог Корпорации СКЭСС",
        point: 5,
        text: "На протяжении сотрудничества команда Студии SAGIROV.co демонстрирует высокий профессионализм, гибкость и быстрое реагирование в решении поставленных задач по поддержке и техническому обслуживанию сайта, индивидуальный подход в решении поставленных задач, а также их своевременное выполнение. Нам удалось построить эффективную коммуникацию, дружеские и доверительные отношения.",
        icon: "skess-paginator.svg",
      },
    ],

    footerText: (
      <>
        следующий <br /> отзыв
      </>
    ),
  },

  priceSection: {
    title: "Цены",
    subtitle:
      "Ценообразование всегда максимально прозрачно и рассчитывается по простой формуле",
    dopclasses: "servicepage-v2",
    items: [
      {
        type: "price",
        title: "Простая формула расчета",
        caption: "рассчитаем честную цену по принципу Time&Material",
        isCross: true,
        items: [
          {
            title1: "Кол-во часов",
            caption1:
              "это затраченное время на решение задач в рамках договора",
            title2: "1800₽/час",
            caption2: "от 50 часов в месяц",
            isCross: true,
            isSmall: false,
          },
          {
            title1: "",
            caption1: "",
            title2: "2000₽/час",
            caption2: "до 50 часов в месяц",
            isSmall: true,
          },
          {
            title1: "",
            caption1: "",
            title2: "2300₽/час",
            caption2: "до 20 часов в месяц",
            isCross: false,
            isSmall: true,
          },
        ],
      },
      {
        type: "formats",
        title: "Прозрачная отчетность",
        caption: "",
        items: [
          {
            title: "Доступ к time трекеру",
            subtitle:
              "Вы получаете полный прозрачный доступ ко всем отчетам о времени и задачах",
          },
          {
            title: "ежемесячный отчет",
            subtitle:
              "Каждый месяц мы формируем для вас детальный отчет о проденной работе",
          },
          {
            document: {
              name: "пример отчета за месяц ",
              size: "592 Кб",
              date: "отчет за апрель",
              href: "#",
            },
          },
        ],
      },
      {
        type: "formats",
        title: "Форматы оплаты",
        caption: "",
        items: [
          {
            title: "Time material",
            subtitle:
              "Этот формат предоставляет максимальную гибкость. Вы платите только за фактически затраченное время и материалы на проект.",
          },
          {
            title: "RETAINER",
            subtitle:
              "Вы оплачиваете фиксированную сумму ежемесячно, и мы гарантируем приоритетное обслуживание и гибкий доступ к нашим ресурсам.",
          },
        ],
      },
    ],
  },
  jobTypes: {
    title: "УСЛУГИ",
    subtitle:
      "Решаем все задачи по дизайну с детальным погружением в ваш бизнес",
    items: [
      {
        title: "",
        caption: [
          "сайты и приложения",
          "визуальные эффекты",
          "баннеры",
          "рекламные креативы",
          "презентации",
          "буклеты",
          "лайтбоксы",
          "креативы для веба",
        ],
        items: [
          {
            type: "titled-list",
            title: "делаем",
            items: [
              "дизайн лендингов и сайтов",
              "дизайн новых разделов сайта",
              "",
              "комплексная айдентика",
              "дизайн-сопровождение маркетинговых коммуникаций",
              "",
              "уникальные задачи по запросу",
            ],
          },
          {
            type: "titled-list",
            title: "Инструменты",
            items: [
              "figma",
              "illustrator",
              "photoshop",
              "after effects",
              "indesign",
              "principle",
            ],
          },
        ],
      },
    ],
  },
  seo: {
    title: "Дизайн-поддержка и регулярные обновления | SAGIROV",
    description:
        "Предоставляем комплексные услуги по дизайну: от корректировок и улучшений интерфейсов до создания новых визуальных элементов. Держим ваш продукт на пике актуальности.",
    keywords: [
      "дизайн поддержка",
      "обновление дизайна",
      "создание графических материалов",
      "редизайн сайтов",
      "поддержка интерфейсов",
      "дизайн новых разделов сайта",
    ],
    openGraph: {
      type: "article",
      title: "Дизайн-поддержка и регулярные обновления | SAGIROV",
      description:
          "Предоставляем комплексные услуги по дизайну: от корректировок и улучшений интерфейсов до создания новых визуальных элементов. Держим ваш продукт на пике актуальности.",
    },
  },
};

export default servicesItem;
