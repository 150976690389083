import List from "components/common/List";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ViewHomeLeadSectionSlider from "./ViewHomeLeadSectionSlider";
import classNames from "classnames";
import gsap, { Timeline } from "gsap";
import i18n from "i18next";
import { useLocation } from "react-router-dom";

import { useElemRef } from "hooks/useElemRef";

import ChangeTransition, {
  ChangeTransitionOnTransitionParams,
} from "components/common/ChangeTransition/ChangeTransition";

import Print from "components/common/Print";

import ViewHomeFlipBookSection from "../ViewHomeFlipBookSection";

import { useViewAssets } from "hooks/useAssets";

import Section from "components/layouts/Section";
import { repeatItem } from "helpers/common/array";
import { WordsType } from "components/common/MorphSvg/words";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";
import ScrollDownButton from "components/layouts/ScrollDownButton";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface ViewHomeLeadSectionProps {}

const ViewHomeLeadSection = (props: ViewHomeLeadSectionProps) => {
  const { getAsset } = useViewAssets("home");

  const { t } = useTranslation();
  let svgTitle = [
    t("view:home.lead.svg.sites"),
    t("view:home.lead.svg.branding"),
  ];
  let slides = svgTitle.map((data) => data) as WordsType[];

  let languageCode = useMemo(() => {
    return i18n.language === "en" ? "en" : "ru";
  }, [i18n.language]);

  const location = useLocation();
  useEffect(() => {
    languageCode = i18n.language === "en" ? "en" : "ru";
  }, [location]);

  const getFlipBookFrames = useCallback(
    (id: string, totalFrames: number, breakpointName?: string) => {
      return Array.from({ length: totalFrames - 1 }, (_, i) => {
        const folder = ["flip-books", id];

        if (breakpointName) {
          folder.push(breakpointName);
        }

        return getAsset({
          folder,
          file: {
            name: i.toString().padStart(3, "0"),
            ext: "webp",
          },
        });
      });
    },
    [getAsset]
  );

  const getFlipBook = useCallback(
    (
      id: string,
      totalFrames: number,
      options?: {
        repeatFrame?: { [key in number]: number };
        breakpointsMedia?: { [key: string]: string };
        language?: string;
      }
    ) => {
      const frames = getFlipBookFrames(
        id,
        totalFrames,
        "desktop" + (!options?.language ? "" : "_" + options?.language)
      );
      const breakpointsFrames: { media: string; data: string[] }[] = [];
      const repeatedFrames = options?.repeatFrame;

      if (options?.breakpointsMedia) {
        for (const breakpointName in options?.breakpointsMedia) {
          const breakpointMedia = options?.breakpointsMedia[breakpointName];
          const breakpointFrames = getFlipBookFrames(
            id,
            totalFrames,
            breakpointName + (!options?.language ? "" : "_" + options?.language)
          );

          breakpointsFrames.push({
            media: breakpointMedia,
            data: repeatedFrames
              ? repeatItem(breakpointFrames, repeatedFrames)
              : breakpointFrames,
          });
        }
      }

      return {
        frames: repeatedFrames ? repeatItem(frames, repeatedFrames) : frames,
        breakpointsFrames,
      };
    },
    [getFlipBookFrames]
  );

  const leadSectionData = useMemo(
    () => [
      {
        list: [
          t("view:home.lead.list.0.0"),
          t("view:home.lead.list.0.1"),
          t("view:home.lead.list.0.2"),
          "",
          t("view:home.lead.list.0.3"),
          t("view:home.lead.list.0.4"),
          t("view:home.lead.list.0.5"),
          t("view:home.lead.list.0.6"),
          "",
          t("view:home.lead.list.0.7"),
        ],
        flipBook: getFlipBook("sites", 397, {
          language: languageCode,
        }),
      },
      {
        list: [
          t("view:home.lead.list.1.0"),
          t("view:home.lead.list.1.1"),
          t("view:home.lead.list.1.2"),
          t("view:home.lead.list.1.3"),
          t("view:home.lead.list.1.4"),
          "",
          t("view:home.lead.list.1.5"),
          t("view:home.lead.list.1.6"),
          "",
          t("view:home.lead.list.1.7"),
          t("view:home.lead.list.1.8"),
        ],
        flipBook: getFlipBook(t("view:home.lead.canvas"), 220, {
          language: "",
        }),
      },
    ],
    [getFlipBook, location]
  );

  const { setElemRef, elemRef } = useElemRef({
    poolOfWorksList: null,
    poolOfWorksListItems: [],
    poolOfWorksListLabel: null,
    slider: null,
    slogan: null,
    arrow: null,
    showreelBtn: null,
    colReward: null,
    videoScroll: null,
  });

  const sectionRef = useRef<HTMLDivElement>(null);

  const [activeListIndex, setActiveListIndex] = useState(0);

  // находим максимальное кол-во элементов списка
  useMemo(() => {
    return leadSectionData.reduce(
      (maxLength, { list }) =>
        maxLength > list.length ? maxLength : list.length,
      0
    );
  }, [leadSectionData]);

  const listTimelineRef = useRef<gsap.core.Timeline>();
  const flipBookTimelineRef = useRef<gsap.core.Timeline>();

  const handleSlideChange = (itemIndex: number) => {
    setActiveListIndex(itemIndex);
  };

  // инициализация анимации появления первой секции
  useEffect(() => {
    const elem = elemRef.current!;
    const timeline = gsap.timeline({
      defaults: { ease: "ease-out", duration: 0.8 },
      delay: 1,
    });

    const sliderSlidesElms = Array.from(
      document.querySelectorAll(".view-home-lead-section .swiper-slide")
    );

    const showSlidesTween = gsap.from(sliderSlidesElms, {
      y: 50,
      stagger: {
        amount: 0.3,
      },
      opacity: 0,
      duration: 0.5,
      ease: "ease-out",
    });

    const showArrowTween = gsap.from([elem.arrow], {
      y: -50,
      opacity: 0,
      ease: "ease-out",
    });

    const showPoolOfWorksList = gsap.from(elem.poolOfWorksList, {
      y: 40,
    });

    const showVideoSroll = gsap.from(elem.videoScroll, {
      y: -50,
      opacity: 0,
      ease: "ease-out",
    });

    const showColRewards = gsap.from(elem.colReward, {
      y: -50,
      opacity: 0,
      ease: "ease-out",
    });

    const showPoolOfWorksListItems = gsap.from(elem.poolOfWorksListItems, {
      y: 20,
      opacity: 0,
      stagger: {
        amount: 0.3,
      },
      duration: 0.5,
    });

    const showSloganAndShowreelBtn = gsap.from(
      [elem.slogan, elem.showreelBtn, elem.poolOfWorksListLabel],
      {
        y: 20,
        opacity: 0,
        ease: "ease-out",
      }
    );

    timeline.add([
      showSlidesTween,
      showArrowTween,
      showSloganAndShowreelBtn,
      showPoolOfWorksList,
      showPoolOfWorksListItems,
      showVideoSroll,
      showColRewards,
    ]);

    timeline.set(elem.showreelBtn, {
      clearProps: "all",
    });

    return () => {
      timeline.kill();
    };
  }, [elemRef]);

  // анимирует смену списка с пулом работ
  const handlePoolOfWorksListTransition = ({
    done,
    state,
    node,
  }: ChangeTransitionOnTransitionParams) => {
    //получаем все элементы списка
    const listItems =
      node?.querySelectorAll(".view-home-lead-section__do-list-item") || [];

    //получаем таймлайн
    const timeline =
      listTimelineRef.current || (listTimelineRef.current = gsap.timeline());

    if (timeline.progress() !== 1) {
      timeline.clear();
    }

    if (state === "exit") {
      timeline.fromTo(
        node,
        {
          y: 0,
          visibility: "",
        },
        {
          y: -40,
          duration: 0.4 + (listItems.length - 1) * 0.1,
          ease: "ease-out",
        }
      );

      timeline.fromTo(
        listItems,
        {
          y: 0,
          opacity: 1,
        },
        {
          y: -20,
          opacity: 0,
          stagger: 0.1,
          duration: 0.4,
          ease: "ease-out",
        },
        "<"
      );

      timeline.add(done);
    } else if (state === "enter") {
      timeline.fromTo(
        node,
        {
          y: 40,
        },
        {
          y: 0,
          duration: 0.4 + (listItems.length - 1) * 0.1,
          ease: "ease-in",
        }
      );

      timeline.fromTo(
        listItems,
        {
          y: 20,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          stagger: 0.1,
          duration: 0.4,
          ease: "ease-in",
        },
        "<"
      );

      timeline.add(done);
    }
  };

  const handleFlipBookChangeTransition = ({
    done,
    state,
    node,
  }: ChangeTransitionOnTransitionParams) => {
    const timeline =
      flipBookTimelineRef.current ||
      (flipBookTimelineRef.current = gsap.timeline());

    if (timeline.progress() !== 1) {
      timeline.clear();
    }

    if (state === "exit") {
      timeline.fromTo(
        node,
        {
          y: 0,
          opacity: 1,
        },
        {
          y: 40,
          opacity: 0,
          ease: "ease-out",
        }
      );

      timeline.add(done);
    } else if (state === "enter") {
      timeline.fromTo(
        node,
        {
          y: 40,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          ease: "ease-in",
        }
      );

      timeline.add(done);
    }
  };

  const getLink = (text: keyof typeof links) => {
    let links = {
      'Identity': '/services/branding',
      'Packaging': '/services/branding',
      'Advertising creatives': '/services/branding',
      'Logotype': '/services/branding',
      '3D design': '/services/branding',
      'motion': '/services/branding',
      'advertisement': '/services/branding',
      'smm design': '/services/branding',

      'Design ux': '/services/ux-ui',
      'Interfaces': '/services/ux-ui',
      'Sites': '/services/ux-ui',
      'Applications': '/services/ux-ui',
      'Ios': '/services/ux-ui',
      'android': '/services/ux-ui',
      'UI animation': '/services/ux-ui',

      'Design support': '/services/design-support',
      'design support': '/services/design-support',

      'Websites': '/services/website-development',
      'Landings': '/services/website-development',
      'Online-shops': '/services/website-development',
      'Corp sites': '/services/website-development',
      'Portals': '/services/website-development',
      'ecommerce': '/services/website-development',
      'Content': '/services/website-development',

      'Maintenance': '/services/technical-support',
      'outstaff': '/services/outstaffing',
    };

    if (links.hasOwnProperty(text)) {
      return links[text];
    }

    return '';
  };

  return (
    <>
      <DynamicBackgroundTrigger>
        <Section ref={sectionRef} className="view-home-lead-section">
          <div className="view-home-lead-section__container space--header">
            <div className="view-home-lead-section__inner">
              <ViewHomeLeadSectionSlider
                ref={setElemRef("slider")}
                onChange={handleSlideChange}
                items={slides}
              />
              <div className="view-home-lead-section__main container">
                <div className="view-home-lead-section__main-inner row">
                  <div className="view-home-lead-section__col-with-what-we-do-and-showreel col-11 col-md-5 col-xl-3 order-md-2 order-1 row">
                    <div className="view-home-lead-section__col-with-what-we-do col-11 order-md-last ">
                      <div className="view-home-lead-section__col-with-what-we-do-inner row">
                        <div className="col-4">
                          <p
                            ref={setElemRef("poolOfWorksListLabel")}
                            className="label side-brackets"
                          >
                            <Print data="We do" />
                          </p>
                        </div>
                        <ChangeTransition
                          trigger={activeListIndex}
                          onTransition={handlePoolOfWorksListTransition}
                        >
                          <div className={classNames("col-7")}>
                            <List
                              className="view-home-lead-section__do-list"
                              itemClassName="view-home-lead-section__do-list-item"
                              items={leadSectionData[activeListIndex].list}
                            >
                              {(listItem, i) => {
                                const link = getLink(listItem as any);
                                return link !== null && link !== undefined && link !== '' ? (
                                    <Link
                                        to={link}
                                        ref={setElemRef("poolOfWorksListItems", i)}
                                        className="label text--not-empty link"
                                    >
                                      <p className={'link--normal'}><Print data={listItem} /></p>
                                    </Link>
                                ) : (
                                    <p
                                        ref={setElemRef("poolOfWorksListItems", i)}
                                        className="label text--not-empty"
                                    >
                                      <Print data={listItem} />
                                    </p>
                                );
                              }}
                            </List>
                          </div>
                        </ChangeTransition>
                      </div>
                    </div>
                  </div>

                  <div className="view-home-lead-section__col-with-slogan col-xl-5 col-7 order-xl-first order-4">
                    <p
                      ref={setElemRef("slogan")}
                      className="view-home-lead-section__slogan footnote footnote--adaptive footnote--tight"
                    >
                      <Print data="view:home.lead.slogan" />
                    </p>
                  </div>

                  <div
                    ref={setElemRef("colReward")}
                    className="view-home-lead-section__col-reward offset-md-1 col-md-5 col-11 order-xl-last order-md-2 order-3 d-xl-none d-flex"
                  >
                    <div className="col-4">
                      <p className="label side-brackets">
                        <Print data="Awards" />
                      </p>
                    </div>
                    <div className="list col-7">
                      <div className="item">
                        <p>
                          <Print data="view:home.lead.reward.0.top" />
                          <img src="/images/awards/reward.png" />
                        </p>
                        <span>
                          <Print data="view:home.lead.reward.0.text" />
                        </span>
                      </div>
                      <div className="item">
                        <p>
                          <Print data="view:home.lead.reward.1.top" />
                          <img src="/images/awards/reward.png" />
                        </p>
                        <span>
                          <Print data="view:home.lead.reward.1.text" />
                        </span>
                      </div>
                      <div className="item">
                        <p>
                          <Print data="view:home.lead.reward.2.top" />
                          <img src="/images/awards/reward.png" />
                        </p>
                        <span>
                          <Print data="view:home.lead.reward.2.text" />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="view-home-lead-section__col-with-arrow offset-auto col-xl-auto col-auto col-xl-3 order-5">
                    <div ref={setElemRef("arrow")}>
                      <ScrollDownButton
                        scrollTo=".view-home-portfolio-section"
                        duration={4}
                      />
                    </div>
                  </div>
                </div>
              </div>
            
            </div>
          </div>
        </Section>
      </DynamicBackgroundTrigger>

      <div ref={setElemRef("videoScroll")}>
        <ChangeTransition
          trigger={activeListIndex}
          onTransition={handleFlipBookChangeTransition}
        >
          <div>
            <ViewHomeFlipBookSection
              {...leadSectionData[activeListIndex].flipBook}
            />
          </div>
        </ChangeTransition>
      </div>
    </>
  );
};

export default ViewHomeLeadSection;
