import Head from "components/layouts/Head";
import View from "components/layouts/View";
import Footer from "components/layouts/Footer";
import { memo, useMemo } from "react";
import i18n from "i18next";
import { LangCodeType } from "i18n/types";
import { Redirect, useParams } from "react-router-dom";
import ServicesItemLeadSection from "components/common/services/LeadSection/ServicesItemLeadSection";
import ProjectsSwiperSection from "components/common/services/ProjectsSwiper/ProjectsSwiperSection";
import ServicesFaqsSection from "components/common/services/FaqsSection/FaqsSection";
import ServicesStagesSection from "components/common/services/WorkStages/WorkStages";
import CustomerReviewsSection from "components/common/services/CustomerReviews/CustomerReviewsSection";
import JobPrice from "components/common/services/JobPrice/JobPrice";
import services from "data/services";
import { ServicesItemData } from "data/services/types";
import {
  createSafeConsumer,
  createSafeContext,
  useSafeContext,
} from "contexts/helpers";
import { notFound as notFoundRoute } from "routes";
import AwardsSection from "components/layouts/AwardsSection";
import ServicesItemOurExperienceSection from "components/common/services/OurExperience/OurExpierenceSection";
import JobTypes from "components/common/services/JobTypes/JobTypes";

interface ContextValue {
  data: ServicesItemData;
}

const Context = createSafeContext<ContextValue>();

export const useViewServicesItemContext = () => useSafeContext(Context);
export const ViewPortfolioItemConsumer = createSafeConsumer(Context);

function ViewServicesItem() {
  const params = useParams<{ servicesItemId: string }>();

  const servicesItem = useMemo(() => {
    const result: ServicesItemData | {} = {};

    //текущий язык
    const language = i18n.language as LangCodeType;

    const currentServicesIndex = services[language].findIndex(
      (servicesItemData) => servicesItemData.id === params.servicesItemId
    );

    let servicesItems = services[language];

    if (currentServicesIndex !== -1) {
      const current = servicesItems[currentServicesIndex];
      return current as ServicesItemData;
    }

    return result as ServicesItemData;
  }, [params.servicesItemId, i18n.language]);

  if (servicesItem) {
    const SpecificPortfolioItemView = servicesItem.component;

    return (
      <Context.Provider
        value={{
          //@ts-ignore
          data: servicesItem,
        }}
      >
        <Head seo={servicesItem.seo} />

        <View className="view view--portfolio-item">
          <ServicesItemLeadSection />

          {servicesItem.id !== "outstaffing" && <ProjectsSwiperSection />}

          {servicesItem.componentPlace &&
            servicesItem.componentPlace === 1 &&
            SpecificPortfolioItemView}

          <JobTypes />

          <ServicesItemOurExperienceSection />
          {/* @ts-ignore */}
          {servicesItem.componentPlace &&
            servicesItem.componentPlace === 2 &&
            SpecificPortfolioItemView}
          <JobPrice />
          <CustomerReviewsSection />
          <ServicesStagesSection />
          <ServicesFaqsSection />
          <AwardsSection />
          <Footer />
        </View>
      </Context.Provider>
    );
  }

  console.log(servicesItem);

  return <Redirect to={"/404"} />;
}

export default memo(ViewServicesItem);
