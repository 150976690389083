import brandingEn from "./brandingEn";
import brandingRu from "./brandingRu";
import websiteDevelopmentEn from "./websiteDevelopmentEn";
import websiteDevelopmentRu from "./websiteDevelopmentRu";
import uxUiRu from "./uxUiRu";
import uxUiEn from "./uxUiEn";
import outstaffingEn from "./outstaffingEn";
import outstaffingRu from "./outstaffingRu";
import designSupportEn from "./designSupportEn";
import designSupportRu from "./designSupportRu";
import technicalSupportEn from "./technicalSupportEn";
import technicalSupportRu from "./technicalSupportRu";

const services = {
  en: [
    brandingEn,
    websiteDevelopmentEn,
    uxUiEn,
    outstaffingEn,
    designSupportEn,
    technicalSupportEn,
  ],
  ru: [
    brandingRu,
    websiteDevelopmentRu,
    uxUiRu,
    outstaffingRu,
    designSupportRu,
    technicalSupportRu,
  ],
};

export default services;
