import ViewPortfolioCns from "components/views/ViewPortfolioItem/ViewPortfolioCns/";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";

const porfolioItem: PortfolioItemDataWithComponent = {
  id: "cns",
  component: ViewPortfolioCns,
  title: "cns",
  name: "cns",
  altName: "Brand of bags",
  year: '2023-2024',
  awardsList: [],

  link: {
    label: "cnsbrand.ru",
    href: "https://cnsbrand.ru/",
  },

  socials: [
    {
      label: "behance",
      href: "https://www.behance.net/gallery/193024329/SK10-Construction-company-Real-estate-developer",
    },
  ],

  preview: {
    label: "online store",
    backgroundColor: "#E9E3D7",
    media: {
      type: "video",
      src: "https://vimeo.com/1035509910/8506cd9069",
    },
  },

  poolOfWorks: ["Research", 'UX|UI design', "refinements-of-the-design-system"],

  type: 'site',
  subtype: 'site',

  description: [
    "в рамках нашего проекта мы разработали дизайн интернет-магазина, продающего сумки из натуральной кожи. Мы создали сайт, который сочетает элегантность и функциональность, позволяя пользователям легко находить и приобретать желаемые товары"
  ],
  shortDescription: "Brand of bags",

  seo: {
    title:
        "Development of a high-load online store for an accessories brand with integrations",
    description:
        "We developed an online store for the women's brand CNS with integration of 1C, online payments, and delivery. The implementation led to a 174% increase in conversion, raised the average order value, and automated order processing, reducing manager workload by more than 4 times",
    keywords: [
      "development of an online store",
      "integrations for e-commerce",
      "online accessories store",
      "создание e-commerce сайтов",
      "creation of e-commerce websites",
      "order automation",
      "creation of a high-load online store",
      "online store design"
    ],
    openGraph: {
      type: "article",
      title:
          "Development of a high-load online store for an accessories brand with integrations",
      description:
          "We developed an online store for the women's brand CNS with integration of 1C, online payments, and delivery. The implementation led to a 174% increase in conversion, raised the average order value, and automated order processing, reducing manager workload by more than 4 times",
    },
  },
};

export default porfolioItem;
