import { usePortfolioItemAssets } from 'hooks/useAssets';
import { useViewPortfolioItemContext } from '../ViewPortfolioItem';
import ViewPortfolioItemAboutSection from '../ViewPortfolioItemAboutSection';
import ViewPortfolioItemSection from '../ViewPortfolioItemSection';
import RevealWhenInView from '../../../common/RevealInView/RevealWhenInView';
import { useTranslation } from 'react-i18next';
import VimeoPlayer from "../../../common/VimeoPlayer/VimeoPlayer";
import React from "react";

export interface ViewPortfolioCnsProps {}

const ViewPortfolioCns = (props: ViewPortfolioCnsProps) => {
  const { data } = useViewPortfolioItemContext();
  const { buildPageImageSrc } = usePortfolioItemAssets(data.id);
  const { t } = useTranslation();

  return (
    <>
      <ViewPortfolioItemAboutSection
        dynamicBackgroundColor={true}
        className="view-portfolio-item-about-section--type-cns-site"
        caption={[t("portfolioItem:cns.about.0"), t("portfolioItem:cns.about.1"), t("portfolioItem:cns.about.2"), ]}
        video={{
            src: 'https://vimeo.com/1035509910/8506cd9069',
        }}
        style={{
          backgroundColor: '#E9E3D7',
          color: '#1F1F21',
          backgroundPosition: 'top left',
          backgroundSize: 'cover',
        }}
        name={data.name}
        description={data.description}
      />

      {/* 3ий экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#C1B7AD' }} className={'row view-portfolio-cns-section portfolio-item-section--type-cns-3'}>
          <div className="description container">
              <RevealWhenInView>
                  <div>
                      <p>{t("portfolioItem:cns.section-3.description.0")}</p>
                      <p className={'list'}>{t("portfolioItem:cns.section-3.description.1")}</p>
                  </div>
              </RevealWhenInView>
              <RevealWhenInView><p>{t("portfolioItem:cns.section-3.description.2")}</p></RevealWhenInView>
          </div>
          <RevealWhenInView>
              <img src={buildPageImageSrc(t('portfolioItem:cns.section-3.image'))} alt={'cns'}/>
          </RevealWhenInView>
      </ViewPortfolioItemSection>

      {/* 4ий экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#C1B7AD' }} className={'view-portfolio-cns-section portfolio-item-section--type-cns-4'}>
          <RevealWhenInView>
              <img src={buildPageImageSrc(t('portfolioItem:cns.section-4.image'))} alt={'cns'}/>
          </RevealWhenInView>
      </ViewPortfolioItemSection>

      {/* 5ой экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#E9E3D7', color: '#252529' }} className={'view-portfolio-cns-section portfolio-item-section--type-cns-5 container'}>
            <div className="description">
                <RevealWhenInView>
                    <div>
                        <p>{t("portfolioItem:cns.section-5.description.0")}</p>
                        <p className={'list'}>
                            <span>{t("portfolioItem:cns.section-5.description.1.0")}</span>
                            <span>{t("portfolioItem:cns.section-5.description.1.1")}</span>
                        </p>
                    </div>
                </RevealWhenInView>
                <RevealWhenInView><p>{t("portfolioItem:cns.section-5.description.2")}</p></RevealWhenInView>
            </div>
            <RevealWhenInView>
                <img src={buildPageImageSrc(t('portfolioItem:cns.section-5.image'))} alt={'cns'}/>
            </RevealWhenInView>
        </ViewPortfolioItemSection>

      {/* 6ой экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#E9E3D7' }} className={'view-portfolio-cns-section portfolio-item-section--type-cns-6'}>
            <RevealWhenInView>
                <img src={buildPageImageSrc(t('portfolioItem:cns.section-6.image'))} alt={'cns'}/>
            </RevealWhenInView>
        </ViewPortfolioItemSection>

      {/* 7ой экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#E9E3D7' }} className={'view-portfolio-cns-section portfolio-item-section--type-cns-7 row'}>
          <div className="description container">
              <RevealWhenInView>
                  <div>
                      <p>{t("portfolioItem:cns.section-7.description.0")}</p>
                      <p className={'list'}>
                          <span>{t("portfolioItem:cns.section-7.description.1.0")}</span>
                          <span>{t("portfolioItem:cns.section-7.description.1.1")}</span>
                      </p>
                  </div>
              </RevealWhenInView>
              <RevealWhenInView><p>{t("portfolioItem:cns.section-7.description.2")}</p></RevealWhenInView>
          </div>
          <RevealWhenInView>
              <img src={buildPageImageSrc(t('portfolioItem:cns.section-7.image'))} alt={'cns'}/>
          </RevealWhenInView>
      </ViewPortfolioItemSection>

      {/* 8ый экран*/}
      <ViewPortfolioItemSection
        dynamicBackgroundColor={true}
        style={{ backgroundColor: '#CAAE8E' }} className={'view-portfolio-cns-section portfolio-item-section--type-cns-8'}>
          <RevealWhenInView>
              <img src={buildPageImageSrc(t('portfolioItem:cns.section-8.image.0'))} alt={'cns'}/>
          </RevealWhenInView>
          <div className={"container"}>
              <RevealWhenInView>
                  <img src={buildPageImageSrc(t('portfolioItem:cns.section-8.image.1'))} alt={'cns'}/>
              </RevealWhenInView>
          </div>
      </ViewPortfolioItemSection>

        {/* 9ой экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#E9E3D7' }} className={'container view-portfolio-cns-section portfolio-item-section--type-cns-9 row'}>
            <div className="description">
                <RevealWhenInView>
                    <div>
                        <p>{t("portfolioItem:cns.section-9.description.0")}</p>
                        <p className={'list'}>
                            <span>{t("portfolioItem:cns.section-9.description.1.0")}</span>
                            <span>{t("portfolioItem:cns.section-9.description.1.1")}</span>
                            <span>{t("portfolioItem:cns.section-9.description.1.2")}</span>
                            <span>{t("portfolioItem:cns.section-9.description.1.3")}</span>
                        </p>
                    </div>
                </RevealWhenInView>
                <RevealWhenInView><p>{t("portfolioItem:cns.section-9.description.2")}</p></RevealWhenInView>
            </div>
            <RevealWhenInView>
                <img src={buildPageImageSrc(t('portfolioItem:cns.section-9.image'))} alt={'cns'}/>
            </RevealWhenInView>
        </ViewPortfolioItemSection>

        {/* 10ой экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#E9E3D7' }} className={'view-portfolio-cns-section portfolio-item-section--type-cns-10'}>
            <RevealWhenInView>
                <img src={buildPageImageSrc(t('portfolioItem:cns.section-10.image'))} alt={'cns'}/>
            </RevealWhenInView>
        </ViewPortfolioItemSection>

        {/* 11ой экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#E9E3D7' }} className={'container view-portfolio-cns-section portfolio-item-section--type-cns-11'}>
            <RevealWhenInView>
                <img src={buildPageImageSrc(t('portfolioItem:cns.section-11.image'))} alt={'cns'}/>
            </RevealWhenInView>
        </ViewPortfolioItemSection>

        {/* 12ой экран*/}
        <ViewPortfolioItemSection
            dynamicBackgroundColor={true}
            style={{ backgroundColor: '#CAAE8E' }} className={'container view-portfolio-cns-section portfolio-item-section--type-cns-12'}>
            <RevealWhenInView>
                <img src={buildPageImageSrc(t('portfolioItem:cns.section-12.image'))} alt={'cns'}/>
            </RevealWhenInView>
        </ViewPortfolioItemSection>
    </>
  );
};

export default ViewPortfolioCns;
