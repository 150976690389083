import ViewServicesBrandingItem from "components/views/ViewServicesItem/ViewServicesBrandingItem";
import { ServicesItemData } from "./types";

const servicesItem: ServicesItemData = {
  id: "website-development",
  component: ViewServicesBrandingItem,
  title: "website-development",
  name: "Website Development",

  color: "dark-purple",
  leadSection: {
    title: "Разработка сайтов",
    description:
      "Создание веб проектов на\u00a0современных фрэймворках и\u00a0CMS",
    subdescription: "",
    ourClients: ["sibur", "gazprom", "rosatom", "sberbank", "sk10", "89+"],
    works: [
      {
        title: "делаем",
        items: [
          "веб сайты",
          "лендинги / промо",
          "интернет-магазины",
          "корп сайты",
          "порталы",
        ],
      },
    ],
  },
  sliderSection: [
    {
      title: "Медный голем",
      subtitle: "UX/UI-дизайн и разработка сайта",
      headerItems: [
        "Разработка сайта",
        "UI/UX дизайн",
        "Разработка функционала",
      ],
      body: {
        title: "Задача",
        items: [
          "проектирование и дизайн сайта",
          "верстка и разработка функционала",
        ],
      },
      logo: "/images/developer-sites/cuprum-golem/logo",
      background: "/images/developer-sites/cuprum-golem/background",
      nextSlideButton: <>Следующий проект</>,
    },
    {
      title: "CLT",
      subtitle: "UX/UI-дизайн и разработка сайта",
      headerItems: [
        "разработка сайта",
        "UI/UX дизайн",
        "Разработка функционала",
      ],
      body: {
        title: "Задача",
        items: [
          "проектирование и дизайн сайта",
          "верстка и разработка функционала",
        ],
      },
      logo: "/images/developer-sites/clt/logo",
      background: "/images/developer-sites/clt/background",
      nextSlideButton: (
        <>
          Следующий <br /> проект{" "}
        </>
      ),
    },
    {
      title: "Globe-IT",
      subtitle: "Разработка сайта для производителя насосного оборудования",
      headerItems: [
        "Разработка сайта",
        "UI/UX дизайн",
        "Логотип",
        "разработка функционала",
        "Интеграция с 1с-bitrix",
      ],
      body: {
        title: "Задача",
        items: [
          "Проектирование и разработка сайта",
          "Дизайн и разработка новых разделов",
          "Дизайн и разработка логотипа",
        ],
      },
      logo: "/images/developer-sites/globe-it/logo",
      background: "/images/developer-sites/globe-it/background",
      nextSlideButton: (
        <>
          Следующий <br /> проект{" "}
        </>
      ),
    },
    {
      title: "CFPS",
      subtitle: "Разработка мобильного приложения для контроля финансов",
      headerItems: [
        "разработка сайта",
        "UI/UX дизайн",
        "Разработка функционала",
      ],
      body: {
        title: "Задача",
        items: [
          "проектирование и дизайн сайта",
          "верстка и разработка функционала",
        ],
      },
      logo: "/images/developer-sites/cfps/logo",
      background: "/images/developer-sites/cfps/background",
      nextSlideButton: (
        <>
          Следующий <br /> проект{" "}
        </>
      ),
    },
    {
      title: "Дома Века",
      subtitle: "Разработка и поддержка проекта с 2018 года по Н.В.",
      headerItems: [
        "Разработка сайта",
        "UI/UX дизайн",
        "Тех поддержка",
        "Разработка функционала",
      ],
      body: {
        title: "Задача",
        items: [
          "проектирование и разработка сайта",
          "техническая поддержка сайта",
          "дизайн и разработка новых разделов",
        ],
      },
      logo: "/images/developer-sites/doma-veka/logo",
      background: "/images/developer-sites/doma-veka/background",
      nextSlideButton: (
        <>
          Следующий <br /> проект{" "}
        </>
      ),
    },
  ],

  faqsSection: {
    title: "Faqs",
    items: [
      {
        title: "Сколько времени займет разработка сайта?",
        text: "Разработка сайта обычно занимает от 4 недель, в зависимости от сложности проекта и ваших требований",
      },
      {
        title: "Сколько стоит разработка сайта?",
        text: "Стоимость разработки сайта зависит от множества факторов, включая сложность дизайна, анимации, функциональность и объем контента. Мы можем предоставить точную смету после обсуждения ваших требований",
      },
      {
        title: "Будет ли мой сайт адаптивным и мобильным?",
        text: "Да, все наши сайты разрабатываются с учетом адаптивного дизайна, чтобы они корректно отображались на всех устройствах, включая мобильные телефоны и планшеты.",
      },
      {
        title: "Могу ли я обновлять контент на сайте самостоятельно?",
        text: "Да, мы предоставляем удобную систему управления контентом (CMS), которая позволяет вам легко обновлять текст, изображения и другие элементы на сайте",
      },
      {
        title: "Предоставляете ли вы поддержку после запуска сайта?",
        text: "Да, мы предлагаем различные пакеты поддержки и обслуживания после запуска сайта, чтобы обеспечить его бесперебойную работу и актуальность.",
      },
    ],
  },

  workStagesSection: {
    title: "ЭТАПЫ РАБОТЫ",
    description:
      "Отлаженный процесс работы команды для комфортного сотрудничества и безупречного результата",
    items: [
      {
        tickerText: "ПЕРВЫЙ ЭТАП",
        subtitle: "включение в проект",
        max_step_number: 11,
        items: [
          {
            title: "Брифинг",
            subtitle: "",
            step_number: 1,
            text: "Начальный этап, на  котором мы встречаемся с вами, чтобы понять ваши потребности и ожидания от проекта. обсуждаем его основные задачи и цели",
          },
          {
            title: "Исследование",
            subtitle: "конкурентов",
            step_number: 2,
            text: "Проводим UX-аудит текущего сайта и сайтов конкурентов, выявляем проблемы, анализируем лучшие практики",
          },
          {
            title: "Прототипирование",
            subtitle: "",
            step_number: 3,
            text: "Разрабатываем детализированные прототипы страниц, готовим требования к функционалу и контенту сайта",
          },
        ],
      },
      {
        tickerText: "второй этап",
        subtitle: "Работа над визуалом",
        max_step_number: 11,
        items: [
          {
            title: "Референсы",
            subtitle: "",
            step_number: 4,
            text: "Через референсы для сайта мы определяем общее направление стилистики, фиксируем ключевые идеи. Убеждаемся, что верно уловили идею и думаем в одном направлении с вами",
          },
          {
            title: "Разработка",
            subtitle: "визуальной концепции",
            step_number: 5,
            text: "на основе согласованных референсов разрабатываем варианты визуальной концепции сайта или приложения",
          },
          {
            title: "Дизайн",
            subtitle: "всех страниц, экранов и адаптивов",
            step_number: 6,
            text: "после согласования визуальной концепции переходим к разработке детализированных макетов всех страниц и адаптивов вашего проекта",
          },
          {
            title: "Передача разработке",
            subtitle: "Подготовка макетов к верстке",
            step_number: 7,
            text: "чтобы разработчики могли быстро и эффективно перенести дизайн в код, мы формируем стили для цветов/шрифтов в файле Figma, создаем компоненты всех элементов и их различные состояния, после чего можем передать макеты программистам",
          },
        ],
      },
      {
        tickerText: "ТРЕТИЙ ЭТАП",
        subtitle: "разработка сайта",
        max_step_number: 11,
        items: [
          {
            title: "Front-end",
            subtitle: "разработка",
            step_number: 8,
            text: "Адаптивная верстка всех дизайн-макетов и анимаций с использованием Java Script, react, Vue",
          },
          {
            title: "Back-end",
            subtitle: "разработка",
            step_number: 9,
            text: "Разрабатываем техническую начинку сайта. Проводим все необходимые интеграции. Создаем удобную административную панель для комфортной работы сотрудников. Разграничиваем роли и доступы в «админке»",
          },
        ],
      },
      {
        tickerText: "ЧЕТВЕРТЫЙ ЭТАП",
        subtitle: "разработка сайта",
        max_step_number: 11,
        items: [
          {
            title: "Тестирование",
            subtitle: "",
            step_number: 10,
            text: "Вручную или автоматически загружаем тексты, изображения и другой контент. Проводим комплексное QA-тестирование для того, чтобы ваш сайт корректно работал на всех устройствах и браузерах",
          },
          {
            title: "Техническая",
            subtitle: "поддержка",
            step_number: 11,
            text: "Предоставляем всю необходимую информацию для самостоятельной работы с сайтом. При необходимости оказываем дальнейшую поддержку и помощь в развитии вашего сайта",
          },
        ],
      },
    ],
  },
  customerRewiewsSection: {
    title: "отзывы клиентов",
    subtitle:
      "Мы ответственны в своём деле и всегда нацелены на достижение лучшего результата",
    reviews: [
      {
        title: "Мартынович Михаил",
        subtitle: "руководитель ИТ департамента - застройщик ооо СК10",
        point: 5,
        text: "Команда студии SAGIROV.com показала себя надежным подрядчиком, ответственно, оперативно и квалифицированно выполняющим работы по проектированию, сопровождению и обслуживанию сайта. Мы очень довольны результатами проделанной работы и надеемся на дальнейшее совместное сотрудничество.",
        icon: "sk10-paginator.svg",
      },

      {
        title: "Георги Атоян",
        subtitle: "Генеральный директор Дома-Века",
        point: 5,
        text: "Нам очень нравится результат, который мы получили. Мы давно хотели для себя сайт, который не будет похож на другие, типовые сайты из нашей отрасли. Нашим клиентам он тоже очень нравится. Самое главное, что он получился информативным, современным и понятным. Многие наши конкуренты тоже переделали свои сайты и позаимствовали у нас много идей.",
        icon: "doma-veka-paginator.svg",
      },
      {
        title: "Ильин Игорь",
        subtitle: "Учередитель ООО Новация",
        point: 5,
        text: "Выражаем благодарность Студии Микаэла Сагирова за разработку сайт для нашей компании - быстро и качественно, в процессе работы оперативно вносили правки. Подсказывали как будет лучше и какие вещи нам не подходят на основе аналитики и опыта. По итогу работы разработали дизайн, сайт, выполнили ряд интеграций. Работой довольны, готовы рекомендовать Студию как надежного подрядчика.",
        icon: "nova-group-paginator.svg",
      },
      {
        title: "Екатерина Цыганкова",
        subtitle: "Маркетолог Корпорации СКЭСС",
        point: 5,
        text: "На протяжении сотрудничества команда Студии SAGIROV.co демонстрирует высокий профессионализм, гибкость и быстрое реагирование в решении поставленных задач по поддержке и техническому обслуживанию сайта, индивидуальный подход в решении поставленных задач, а также их своевременное выполнение. Нам удалось построить эффективную коммуникацию, дружеские и доверительные отношения.",
        icon: "skess-paginator.svg",
      },
    ],

    footerText: (
      <>
        следующий <br /> отзыв
      </>
    ),
  },

  priceSection: {
    title: "Цены",
    subtitle:
      "Ценообразование всегда максимально прозрачно и рассчитывается по простой формуле",
    dopclasses: "",
    items: [
      {
        type: "price",
        title: "Разработка сайта",
        caption:
          "Рассчитывается на основе трудозатрат на отдельный вид работ и зависит от количества кругов правок, концепций, объема работ и профиля привлекаемых специалистов",
        items: [
          {
            title1: "Лендинг / Промо",
            caption1: "",
            title2: "от\u00a0300\u00a0000\u00a0р.",
            caption2: "от 15 до 20 дней",
            isCross: false,
          },
          {
            title1: "Интернет-магазин",
            caption1: "",
            title2: "от\u00a0700\u00a0000\u00a0р.",
            caption2: "от 40 до 100 дней",
            isCross: false,
          },
          {
            title1: "Многостраничный сайт",
            caption1: "",
            title2: "от\u00a0900\u00a0000\u00a0р.",
            caption2: "от 45 до 120 дней",
            isCross: false,
          },
        ],
      },

      {
        type: "formats",
        title: "Форматы оплаты",
        caption: "",
        aunchor: {
          to: ".footer",
          // @ts-ignore
          text: (
            <>
              заказать расчет <br /> стоимости проекта
            </>
          ),
        },
        items: [
          {
            title: "fix",
            subtitle:
              "В рамках этого формата работы мы предлагаем фиксированную стоимость за определённый проект или задачу.",
          },
          {
            title: "Time material",
            subtitle:
              "Этот формат предоставляет максимальную гибкость. Вы платите только за фактически затраченное время и материалы на проект.",
          },
          {
            title: "RETAINER",
            subtitle:
              "Вы оплачиваете фиксированную сумму ежемесячно, и мы гарантируем приоритетное обслуживание и гибкий доступ к  нашим ресурсам.",
          },
        ],
      },
    ],
  },

  jobTypes: {
    title: "инструменты и\xa0Технологии",
    subtitle:
      "Решаем ваши задачи с помощью детального анализа и комплексной разработки. Делаем упор на бизнес-задачи и ожидания ваших клиентов",
    items: [
      {
        title: "",
        caption: ["сайты", "веб-приложения", "лендинги", "интернет-магазины"],
        items: [
          {
            type: "double-titled-list",
            title: "Технологии",
            subtitle1: "FRONT-END",
            subtitle2: "BACK-END",
            items: [
              "react js",
              "django",
              "next js",
              "python",
              "vue js",
              "node js",
              "nuxt js",
              "php",
              "html/js/css",
              "laravel",
            ],
          },
        ],
      },
      {
        title: "",
        caption: [
          "делаем",
          "+ аудит и аналитику",
          "+ проектирование",
          "+ дизайн",
          "+ редизайн существующего сайта",
          "+ заполнение контентом",
          "+ поддержка",
        ],
        items: [
          {
            type: "double-titled-list",
            title: "cms",

            items: [
              "1с битрикс",
              "joomla",
              "modx",
              "tilda",
              "wordpress",
              "webflow",
              "opencart",
              "readymag",
              "preashop",
              "wix",
            ],
          },
          {
            type: "titled-list",
            title: "интеграции",

            items: [
              "bitrix 24",
              "amocrm",
              "1c-фитнес",
              "roistat",
              "comagic",
              "mango",
              "и др.",
            ],
          },
        ],
      },
    ],
  },

  ourExpireanceSection: {
    title: (
      <span className="heading-2">
        <i className="heading italic-text">разработка сайтов&#160;—</i> почему
        работать с нами комфортно и удобно
      </span>
    ),
    headText: "преимущества",
    description: "aunchor",
    items: [
      {
        title: "комплексная экспертиза",
        description:
          "В команде разработчики, маркетологи, аналитики, тестировщики, проектные менеджеры",
      },
      {
        title: "опора на данные",
        description:
          "Разрабатываем дизайн на основе аналитики и маркетинговых исследований",
      },
      {
        title: "Соблюдение сроков",
        description:
          "Понимаем, насколько дорого просрочка обходится для бизнеса и не допускаем ее",
      },
      {
        title: "чистый код",
        description:
          "Пишем исходный код без «костылей», используем распространенные технологии. сайт будет легко поддерживать и дорабатывать",
      },
      {
        title: "документация по проекту",
        description:
          "На этапе разработки создаем и предоставляем документацию по работе с сайтом, его наполнению и внесению корректировок",
      },
    ],
  },
  seo: {
    title: "Разработка и создание современных сайтов | SAGIROV",
    description:
        "Предлагаем полный цикл разработки сайтов: от прототипа и дизайна до интеграции и запуска. Создаем быстрые, адаптивные и удобные веб-решения для бизнеса любого масштаба.",
    keywords: [
      "разработка сайтов",
      "создание сайтов",
      "заказать сайт",
      "веб-разработка",
      "дизайн и разработка сайта",
      "корпоративный сайт",
      "интернет-магазин",
      "landing page",
    ],
    openGraph: {
      type: "article",
      title: "Разработка и создание современных сайтов | SAGIROV",
      description:
          "Предлагаем полный цикл разработки сайтов: от прототипа и дизайна до интеграции и запуска. Создаем быстрые, адаптивные и удобные веб-решения для бизнеса любого масштаба.",
    },
  },
};

export default servicesItem;
