import Section from "components/layouts/Section";
import s from "./index.module.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import List from "components/common/List";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import Button from "components/common/Button";
import RevealTextByLine from "components/common/RevealInView/RevealTextByLine";
import SlideFadeOutWhenInView from "components/views/ViewPortfolioItem/ViewPortfolioNovaGroup/SlideFadeOutWhenInView";
import { useEffect, useState } from "react";
import { useViewPortfolioItemContext } from "components/views/ViewPortfolioItem/ViewPortfolioItem";
import { useViewServicesItemContext } from "components/views/ViewServicesItem/ViewServicesItem";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";

function ProjectsSwiperSection({ slides }: { slides?: any[] }) {
  const [backModifier, setBackModifier] = useState(".png");

  function move() {
    const viewport_width = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );
    if (viewport_width <= 20000) {
      setBackModifier(".png");
    }

    if (viewport_width <= 992) {
      setBackModifier("-tab.png");
    }

    if (viewport_width <= 414) {
      setBackModifier("-mob.png");
    }
  }

  useEffect(() => {
    move();
    window.addEventListener("resize", move);
  }, []);

  const { data } = useViewServicesItemContext();

  return (
    <DynamicBackgroundTrigger>
      <Section
        viewportHeight
        className={
          s["services-item-swiper-section"] +
          " text--light services-item-swiper-section"
        }
      >
        <Swiper
          className={s["services-item-swiper-section__swiper"]}
          navigation={{
            nextEl: ".button.navigation",
          }}
          loop
        >
          {/* @ts-ignore */}
          {(slides ? slides : data.sliderSection).map((item, index) => {
            return (
              <SwiperSlide
                key={index}
                className={"row " + s["services-item-swiper-section__slide"]}
                style={{
                  backgroundImage: `url(${item.background + backModifier})`,
                }}
              >
                <div
                  className={s["services-item-swiper-section__slide-container"]}
                >
                  <div>
                    <div className={"row "}>
                      <div className=" col-11 col-md-6 order-1">
                        <div
                          className={
                            "heading col-11 " +
                            s["services-item-swiper-section__title"]
                          }
                        >
                          <span>{item.title}</span>
                        </div>
                      </div>
                      <div className="col-11 col-md-4 order-3">
                        <div className="col-11">
                          {item.subtitle}
                        </div>
                      </div>
                      <div
                        className={
                          "row col-11 col-md-5 offset-0 col-lg-3 offset-lg-2 order-2 " +
                          s["slider-head"]
                        }
                      >
                        <div>
                          <div>
                            <div
                                className={
                                    "row " +
                                    s[
                                        "services-item-swiper-section__right-top-list"
                                        ]
                                }
                            >
                              {/* @ts-ignore */}
                              {item.headerItems.map((item, index) => (
                                  <span
                                      key={index + item}
                                      className={
                                        "side-brackets col-md-11 col-auto label "
                                      }
                                  >
                                    {item}
                                  </span>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                        className={
                            "row " + s["services-item-swiper-section__list"]
                        }
                    >
                      <div className="col-4 col-sm-3 col-md-2 col-lg-1 side-brackets label">
                        {item.body.title}
                      </div>
                      <List
                          items={item.body.items}
                          gap="lg"
                          marked
                          className="col-6 col-md-3"
                      >
                        {(item) => (
                            <span className="text--secondary">{item}</span>
                        )}
                      </List>
                    </div>
                  </div>

                  <footer className={"row "}>
                    <div className={
                        s["services-item-swiper-section__footer-logo"] + " row"
                      }>
                      <div className={" row"}>
                        <img src={item.logo + ".svg"} />
                      </div>
                    </div>
                    <div className={
                        "col-auto offset-auto " +
                        s["services-item-swiper-section__next-slide-button"]
                      }>
                      <Button
                        className={"footnote navigation"}
                        renderRight={() => (
                          <div
                            className={
                              "sprite-icon sprite-icon--arrow-down-white "
                            }
                          />
                        )}
                      >
                        <p>Следующий проект</p>
                      </Button>
                    </div>
                  </footer>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Section>
    </DynamicBackgroundTrigger>
  );
}

export default ProjectsSwiperSection;
