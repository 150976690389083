import RevealTextByLine from "components/common/RevealInView/RevealTextByLine";
import s from "./index.module.scss";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import SlideFadeOutWhenInView from "components/views/ViewPortfolioItem/ViewPortfolioNovaGroup/SlideFadeOutWhenInView";
import List from "components/common/List";
import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";
import { useViewServicesItemContext } from "components/views/ViewServicesItem/ViewServicesItem";

function JobTypes() {
  const { data } = useViewServicesItemContext();

  let sectionData = data.jobTypes!;
  return (
    <DynamicBackgroundTrigger>
      <section
        className={
          s["view-developer-sites-jobs-section"] + " background--dark-white-2"
        }
      >
        <div className={" section-container container"}>
          <div className={""}>
            <div>
              <div
                className={
                  "row " + s["view-developer-sites-jobs-section__header"]
                }
              >
                <RevealWhenInView className="col-xl-7">
                  <h2 className="heading">{sectionData.title}</h2>
                </RevealWhenInView>
                <div className=" offset-md-4 col-md offset-xl-0">
                  <RevealTextByLine
                    wordClassName={(l, w) =>
                      l === 0 && w === 0 && "offset-md-3"
                    }
                  >
                    {sectionData.subtitle}
                  </RevealTextByLine>
                </div>
              </div>
            </div>
            <div>
              {sectionData.items.map((item, index) => {
                const classFirst = index === 0;
                return (
                    <div
                        className={
                            s["view-developer-sites-jobs-section__items-block"] + " row"
                        }
                        key={"id_" + Math.random().toString(36).substr(2, 9)}
                    >
                      <div
                          className={
                              (classFirst ? s["view-developer-sites-jobs-section__items-block-head__first"] : '') +
                              " col-lg-5 col-md-4 " +
                              s["view-developer-sites-jobs-section__items-block-head"]
                          }
                      >
                        {item.title ? (
                            <>
                              <RevealWhenInView>
                                <span className={"heading-2"}>{item.title}</span>
                              </RevealWhenInView>
                              <SlideFadeOutWhenInView>
                                {({ setTriggerEl, setTarget }) => (
                                    <div ref={setTriggerEl}>
                                      <div
                                          ref={setTarget({ selector: "p" })}
                                          className={s["caption-container"] + " row "}
                                      >
                                        {item.caption.map(
                                            (captionItem, captionIndex) => {
                                              return (
                                                  <p
                                                      key={captionIndex}
                                                      className={s.caption}
                                                  >
                                                    {captionItem}
                                                  </p>
                                              );
                                            }
                                        )}
                                      </div>
                                    </div>
                                )}
                              </SlideFadeOutWhenInView>
                            </>
                        ) : (
                            <SlideFadeOutWhenInView>
                              {({ setTriggerEl, setTarget }) => (
                                  <div className="col-11" ref={setTriggerEl}>
                                    <div
                                        ref={setTarget({ selector: "p" })}
                                        className={s["caption-container"] + " row "}
                                    >
                                      {item.caption.map((captionItem, captionIndex) => {
                                        return (
                                            <p
                                                key={captionIndex}
                                                className={s.caption + " col-11 "}
                                            >
                                              {captionItem}
                                            </p>
                                        );
                                      })}
                                    </div>
                                  </div>
                              )}
                            </SlideFadeOutWhenInView>
                        )}
                      </div>

                      <div className="col-lg-6 col-md-7 row ">
                        {item.items.map((item, index) => {
                          if (item.type === "titled-list") {
                            return (
                                <SlideFadeOutWhenInView key={index}>
                                  {({ setTriggerEl, setTarget }) => (
                                      <div
                                          ref={setTriggerEl}
                                          className={
                                            // maybe
                                              "row col-11 " +
                                              s[
                                                  "view-developer-sites-jobs-section__item-block"
                                                  ]
                                          }
                                      >
                                        <div className={"row col-lg-4 col-11 " + s["title"]}>
                                          <p className={"side-brackets label"}>
                                            {item.title}
                                          </p>
                                        </div>
                                        <List
                                            ref={setTarget({ selector: ".list__item" })}
                                            items={item.items}
                                            gap="lg"
                                            marked
                                            className="row col-11 col-md-7"
                                            itemClassName={"col-11 " + s["job-list-item"]}
                                        >
                                          {(item) => <span>{item}</span>}
                                        </List>
                                      </div>
                                  )}
                                </SlideFadeOutWhenInView>
                            );
                            //Лист с заголовком но в два столбца
                          } else if (item.type === "double-titled-list") {
                            return (
                                <SlideFadeOutWhenInView key={index}>
                                  {({ setTriggerEl, setTarget }) => (
                                      <div
                                          className={
                                              s[
                                                  "view-developer-sites-jobs-section__item-block"
                                                  ] + " row col-11"
                                          }
                                          ref={setTriggerEl}
                                      >
                                        <div className={"row col-lg-4 col-11 " + s["title"]}>
                                  <span className="side-brackets heading-3">
                                    {item.title}
                                  </span>
                                        </div>
                                        {item.subtitle1 && item.subtitle2 && (
                                            <div
                                                className={
                                                    "row offset-lg-auto col-lg-7 col-11  " +
                                                    s["subtitles-container"]
                                                }
                                            >
                                    <span className={" col-5"}>
                                      {item.subtitle1}
                                    </span>
                                              <span className={"col-5 offset-1"}>
                                      {item.subtitle2}
                                    </span>
                                            </div>
                                        )}

                                        <div
                                            ref={setTarget({
                                              selector: ".list-selector",
                                            })}
                                            className="row offset-lg-auto col-lg-7 col-11"
                                        >
                                          {item.items.map((item, index) => {
                                            return (
                                                <p
                                                    className={
                                                        (index % 2 === 0
                                                            ? "col-5 "
                                                            : "col-5 offset-1 ") +
                                                        s["cursive-text-item"] +
                                                        "  list-selector row "
                                                    }
                                                    key={index}
                                                >
                                                  <span className={s["plus"]}>+</span>
                                                  <span className={s["text"]}>{item}</span>
                                                </p>
                                            );
                                          })}
                                        </div>
                                      </div>
                                  )}
                                </SlideFadeOutWhenInView>
                            );
                            // Лист c заголовком сверху
                          } else if (item.type === "heading-titled-list") {
                            return (
                                <div
                                    className={
                                        " offset-0  offset-md-2  offset-lg-0 g-0 row " +
                                        s[
                                            "view-developer-sites-jobs-section__item-block"
                                            ] +
                                        " "
                                    }
                                    key={index}
                                >
                                  <SlideFadeOutWhenInView>
                                    {({ setTriggerEl, setTarget }) => (
                                        <div
                                            key={
                                                "id_" +
                                                Math.random().toString(36).substr(2, 9)
                                            }
                                            className={" row"}
                                            ref={setTriggerEl}
                                        >
                                          <div
                                              className={
                                                  "row col-11 " +
                                                  s["heading-titled-list__title"]
                                              }
                                          >
                                    <span className="heading-3">
                                      {item.title}
                                    </span>
                                          </div>
                                          <List
                                              ref={setTarget({ selector: ".list__item" })}
                                              items={item.items}
                                              gap="lg"
                                              marked
                                              className="row col-6"
                                          >
                                            {(item) => (
                                                <span className="col-11">{item}</span>
                                            )}
                                          </List>
                                        </div>
                                    )}
                                  </SlideFadeOutWhenInView>
                                </div>
                            );
                            // Лист без заголовка
                          } else if (item.type === "list") {
                            return (
                                <SlideFadeOutWhenInView key={index}>
                                  {({ setTriggerEl, setTarget }) => (
                                      <div
                                          key={index}
                                          ref={setTriggerEl}
                                          className={
                                              s[
                                                  "view-developer-sites-jobs-section__item-block"
                                                  ] +
                                              " col-11 row"
                                          }
                                      >
                                        {item.title && (
                                            <div className={"row col-lg-4 col-11 " + s["title"]}>
                                              <span className="side-brackets heading-3">
                                                {item.title}
                                              </span>
                                            </div>
                                        )}
                                        <div
                                            className="row col-lg-6 col-md-7"
                                            ref={setTarget({ selector: ".heading-3" })}
                                        >
                                          {item.items.map((item) => {
                                            return (
                                                <span
                                                    className={
                                                        "heading-3 col-11 " + s["list-item"]
                                                    }
                                                    key={
                                                        "id_" +
                                                        Math.random()
                                                            .toString(36)
                                                            .substr(2, 9)
                                                    }
                                                >
                                        {item}
                                      </span>
                                            );
                                          })}
                                        </div>
                                      </div>
                                  )}
                                </SlideFadeOutWhenInView>
                            );
                          }
                          return <></>;
                        })}
                      </div>
                    </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </DynamicBackgroundTrigger>
  );
}

export default JobTypes;
