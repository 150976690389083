import ViewPortfolioCuprum from "components/views/ViewPortfolioItem/ViewPortfolioCuprum/";
import { PortfolioItemDataWithComponent } from "data/portfolio/types";

const porfolioItem: PortfolioItemDataWithComponent = {
  id: "cuprum",
  component: ViewPortfolioCuprum,
  title: "cuprum",
  name: "Медный голем",
  altName: "Сайт-портфолио \n для студии\nхудожественного литья",
  year: '2023',
  awardsList: [],

  link: {
    label: "rostov-golem.ru",
    href: "https://rostov-golem.ru/",
  },

  socials: [
    {
      label: "behance",
      href: "https://www.behance.net/gallery/203561147/WEBSITE-sopper-golem-foundry-industry",
    },
  ],

  preview: {
    label: "корпоративный сайт",
    backgroundColor: "#E9E3D7",
    media: {
      type: "video",
      src: "https://vimeo.com/1035508536",
    },
  },

  poolOfWorks: ['UX|UI design', 'Page making'],

  type: 'site',
  subtype: 'site',

  description: [
    "сайт-презентация для компании, которая занимается художественным литьем и изготовлением скульптур из меди.",
    "Сайт работает как портфолио и позволяет легко делиться ссылкой с клиентами, что делает процесс демонстрации работ проще и приятнее"
  ],
  shortDescription: "Сайт-портфолио для студии художественного литья",

  seo: {
    title:
        "Разработка сайта для литейной мастерской 'Медный Голем' | Монументы и мемориальные комплексы",
    description:
        "Создали сайт для компании 'Медный Голем', специализирующейся на создании монументов и памятников. Работы компании установлены в парке Галицкого и других знаковых местах. Компания также занимается реконструкцией, благоустройством и освещением мемориальных комплексов",
    keywords: [
      "разработка сайта для производственной компании",
      "кейс Медный Голем",
      "сайт для литейной мастерской",
      "сайт промышленной компании",
      "благоустройство мемориальных комплексов",
      "сайт реставрация мемориалов",
      "декоративное освещение",
      "парк Галицкого",
      "Князь Игорь",
      "Саур-Могила"
    ],
    openGraph: {
      type: "article",
      title:
          "Разработка сайта для производственной компании 'Медный Голем' | Монументы и мемориальные комплексы",
      description:
          "Создали сайт для компании 'Медный Голем', специализирующейся на создании монументов и памятников. Работы компании установлены в парке Галицкого и других знаковых местах. Компания также занимается реконструкцией, благоустройством и освещением мемориальных комплексов",
    },
  },
};

export default porfolioItem;
