import { DynamicBackgroundTrigger } from "components/common/DynamicBackground";
import List from "components/common/List";
import RevealWhenInView from "components/common/RevealInView/RevealWhenInView";
import Print from "components/common/Print";
import SlideFadeOutWhenInView from "../ViewPortfolioItem/ViewPortfolioNovaGroup/SlideFadeOutWhenInView";
import RevealTextByLine from "components/common/RevealInView/RevealTextByLine";
import { useMemo } from "react";
import { ExperienceLevel } from "data/types";
interface VacancyExperienceSectionProps { }

const getExperienceLevel = (level: ExperienceLevel, totalAboutLines: number) => {
  const translateKey = `view:vacancies.experience.level.${level}`

  return {
    name: `${translateKey}.name`,
    about: Array.from(
      { length: totalAboutLines },
      (_, i) => `${translateKey}.about.${i}`
    ),
  }
}

const VacancyExperienceSection = (props: VacancyExperienceSectionProps) => {
  const experienceLevels = useMemo(() => {
    const levels: [ExperienceLevel, number][] = [
      ["intern", 2],
      ["junior", 2],
      ["middle", 2],
      ["senior", 1],
    ]

    return levels.map((level) => getExperienceLevel(level[0], level[1]));
  }, []);

  return (
    <DynamicBackgroundTrigger>
      <section className="vacancy-experience-section background--red">
        <div className="vacancy-experience-section__container container text--light">
          <h2 className="vacancy-experience-section__heading heading">
            {/* <RevealWhenInView tag="span" inline> */}
            <Print data="view:vacancies.experience.title">
              {(text) => <RevealTextByLine>{text}</RevealTextByLine>}
            </Print>
            {/* </RevealWhenInView> */}
          </h2>

          <RevealWhenInView
            tag="p"
            targetTag="span"
            className="vacancy-experience-section__subheading"
          >
            <span className="label side-brackets">
              <Print data="view:vacancies.experience.subtitle" />
            </span>
          </RevealWhenInView>

          <List
            className="vacancy-experience-section__level-list row"
            itemClassName="vacancy-experience-section__level-item col-11 offset-md-2 col-md-7 offset-xl-0 col-xl-4"
            items={experienceLevels}
          >
            {(experienceLevel) => (
              <SlideFadeOutWhenInView>
                {({ setTarget, setTriggerEl }) => (
                  <div ref={setTriggerEl}>
                    <p
                      ref={setTarget()}
                      className="vacancy-experience-section__level-item-label label side-brackets"
                    >
                      {<Print data={experienceLevel.name} />}
                    </p>

                    <List
                      ref={setTarget({ selector: ".list__item" })}
                      items={experienceLevel.about}
                      gap="lg"
                      marked
                    >
                      {(item) => <Print data={item} />}
                    </List>
                  </div>
                )}
              </SlideFadeOutWhenInView>
            )}
          </List>
        </div>
      </section>
    </DynamicBackgroundTrigger>
  );
};

export default VacancyExperienceSection;
