import ViewServicesLeadSection from "./ViewServicesLeadSection";
import ViewServicesServiceSection from "./ViewServicesServiceSection";
import PortfolioLinkSection from "components/layouts/PortfolioLinkSection";
import View from "components/layouts/View";
import Footer from "components/layouts/Footer";
import CustomerReviewsSection from "components/common/services/CustomerReviews/CustomerReviewsSection";
import ProjectsSwiperSection from "components/common/services/ProjectsSwiper/ProjectsSwiperSection";
import ViewServicesListSection from "./ViewServicesListSection";

interface ViewServicesProps {}

const ViewServices = (props: ViewServicesProps) => {
  return (
    <View className="view--services">
      <ViewServicesLeadSection />
      <ViewServicesListSection />
      <CustomerReviewsSection />
      <ProjectsSwiperSection
        slides={[
          {
            title: "SK10",
            subtitle: "Разработка и поддержка проекта с 2019 года по Н.В.",
            headerItems: [
              "разработка сайта",
              "UI/UX дизайн",
              "Тех поддержка",
              "Разработка функционала",
            ],
            body: {
              title: "Задача",
              items: [
                "проектирование и разработка сайта",
                "техническая поддержка сайта",
                "Дизайн и разработка новых разделов",
              ],
            },
            logo: "/images/developer-sites/sk10/logo",
            background: "/images/developer-sites/sk10/background",
            nextSlideButton: <>Следующий проект</>,
          },
          {
            title: "Дома века",
            subtitle: "Разработка и поддержка проекта с 2018 года по Н.В.",
            headerItems: [
              "разработка сайта",
              "UI/UX дизайн",
              "Тех поддержка",
              "Разработка функционала",
            ],
            body: {
              title: "Задача",
              items: [
                "проектирование и разработка сайта",
                "техническая поддержка сайта",
                "Дизайн и разработка новых разделов",
              ],
            },
            logo: "/images/developer-sites/doma-veka/logo",
            background: "/images/developer-sites/doma-veka/background",
            nextSlideButton: (
              <>
                Следующий <br /> проект{" "}
              </>
            ),
          },
          {
            title: "AAG",
            subtitle: "Разработка и поддержка проекта с 2023 года по Н.В.",
            headerItems: [
              "Тех поддержка",
              "UI/UX дизайн",
              "Разработка функционала",
            ],
            body: {
              title: "Задача",
              items: [
                "техническая поддержка сайта",
                "Дизайн и разработка новых разделов",
              ],
            },
            logo: "/images/developer-sites/aag/logo",
            background: "/images/developer-sites/aag/background",
            nextSlideButton: (
              <>
                Следующий <br /> проект{" "}
              </>
            ),
          },
          {
            title: "НОВАЦИЯ",
            subtitle: "Разработка и поддержка проекта с 2014 года по Н.В.",
            headerItems: [
              "разработка сайта",
              "UI/UX дизайн",
              "Тех поддержка",
              "Разработка функционала",
            ],
            body: {
              title: "Задача",
              items: [
                "проектирование и разработка сайта",
                "техническая поддержка сайта",
                "Дизайн и разработка новых разделов",
              ],
            },
            logo: "/images/developer-sites/nova-group/logo",
            background: "/images/developer-sites/nova-group/background",
            nextSlideButton: (
              <>
                Следующий <br /> проект{" "}
              </>
            ),
          },
        ]}
      />
      <PortfolioLinkSection className="view-services-portfolio-link-section" />
      <Footer />
    </View>
  );
};

export default ViewServices;
